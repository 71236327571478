@font-face {
  font-family: "Lindua";
  src: url("./Lindua.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

[class^="liga-"],
[class*=" liga-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Lindua" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.liga-home:before {
  content: "\e900";
}
.liga-home2:before {
  content: "\e901";
}
.liga-home3:before {
  content: "\e902";
}
.liga-home4:before {
  content: "\e903";
}
.liga-home-lock:before {
  content: "\e904";
}
.liga-home-sleep:before {
  content: "\e905";
}
.liga-home-smart:before {
  content: "\e906";
}
.liga-home-user:before {
  content: "\e907";
}
.liga-faucet:before {
  content: "\e908";
}
.liga-sink:before {
  content: "\e909";
}
.liga-plunger:before {
  content: "\e90a";
}
.liga-bathtub:before {
  content: "\e90b";
}
.liga-rubber-duck:before {
  content: "\e90c";
}
.liga-towel:before {
  content: "\e90d";
}
.liga-toothbrush:before {
  content: "\e90e";
}
.liga-razor:before {
  content: "\e90f";
}
.liga-bed:before {
  content: "\e910";
}
.liga-bed-time:before {
  content: "\e911";
}
.liga-bed-check:before {
  content: "\e912";
}
.liga-bed-cross:before {
  content: "\e913";
}
.liga-couch:before {
  content: "\e914";
}
.liga-chair:before {
  content: "\e915";
}
.liga-office:before {
  content: "\e916";
}
.liga-city:before {
  content: "\e917";
}
.liga-pencil:before {
  content: "\e918";
}
.liga-pencil2:before {
  content: "\e919";
}
.liga-pen:before {
  content: "\e91a";
}
.liga-pencil3:before {
  content: "\e91b";
}
.liga-eraser:before {
  content: "\e91c";
}
.liga-edit:before {
  content: "\e91d";
}
.liga-edit2:before {
  content: "\e91e";
}
.liga-notebook:before {
  content: "\e91f";
}
.liga-quill:before {
  content: "\e920";
}
.liga-edit3:before {
  content: "\e921";
}
.liga-blog:before {
  content: "\e922";
}
.liga-pen2:before {
  content: "\e923";
}
.liga-pen-plus:before {
  content: "\e924";
}
.liga-pen-minus:before {
  content: "\e925";
}
.liga-pen-drawing:before {
  content: "\e926";
}
.liga-bezier-curve:before {
  content: "\e927";
}
.liga-bezier-curve2:before {
  content: "\e928";
}
.liga-brush:before {
  content: "\e929";
}
.liga-brush2:before {
  content: "\e92a";
}
.liga-paint-roller:before {
  content: "\e92b";
}
.liga-spray:before {
  content: "\e92c";
}
.liga-spray-cross:before {
  content: "\e92d";
}
.liga-spray-bug:before {
  content: "\e92e";
}
.liga-spray2:before {
  content: "\e92f";
}
.liga-window:before {
  content: "\e930";
}
.liga-tape-measure:before {
  content: "\e931";
}
.liga-tape:before {
  content: "\e932";
}
.liga-tape-dispenser:before {
  content: "\e933";
}
.liga-stamp:before {
  content: "\e934";
}
.liga-texture:before {
  content: "\e935";
}
.liga-tile:before {
  content: "\e936";
}
.liga-eyedropper:before {
  content: "\e937";
}
.liga-palette:before {
  content: "\e938";
}
.liga-color-sampler:before {
  content: "\e939";
}
.liga-bucket:before {
  content: "\e93a";
}
.liga-sticker:before {
  content: "\e93b";
}
.liga-gradient:before {
  content: "\e93c";
}
.liga-magic-wand:before {
  content: "\e93d";
}
.liga-magnet:before {
  content: "\e93e";
}
.liga-magnet-cross:before {
  content: "\e93f";
}
.liga-magnet-grid:before {
  content: "\e940";
}
.liga-magnet-point:before {
  content: "\e941";
}
.liga-magnet-angle:before {
  content: "\e942";
}
.liga-magnet-scale:before {
  content: "\e943";
}
.liga-pencil-ruler:before {
  content: "\e944";
}
.liga-pencil-ruler2:before {
  content: "\e945";
}
.liga-compass:before {
  content: "\e946";
}
.liga-polygon:before {
  content: "\e947";
}
.liga-polygon2:before {
  content: "\e948";
}
.liga-polygon3:before {
  content: "\e949";
}
.liga-sword:before {
  content: "\e94a";
}
.liga-gun:before {
  content: "\e94b";
}
.liga-aim:before {
  content: "\e94c";
}
.liga-grenade:before {
  content: "\e94d";
}
.liga-bomb:before {
  content: "\e94e";
}
.liga-explosion:before {
  content: "\e94f";
}
.liga-nuclear:before {
  content: "\e950";
}
.liga-bottle:before {
  content: "\e951";
}
.liga-drop:before {
  content: "\e952";
}
.liga-droplet-plus:before {
  content: "\e953";
}
.liga-droplet-minus:before {
  content: "\e954";
}
.liga-droplet-cross:before {
  content: "\e955";
}
.liga-oil-price:before {
  content: "\e956";
}
.liga-coolant:before {
  content: "\e957";
}
.liga-snowflake:before {
  content: "\e958";
}
.liga-snowflake2:before {
  content: "\e959";
}
.liga-fire:before {
  content: "\e95a";
}
.liga-fire-forbidden:before {
  content: "\e95b";
}
.liga-fire-price:before {
  content: "\e95c";
}
.liga-fire-warning:before {
  content: "\e95d";
}
.liga-fire-hazard:before {
  content: "\e95e";
}
.liga-fireworks:before {
  content: "\e95f";
}
.liga-match-fire:before {
  content: "\e960";
}
.liga-hydrant:before {
  content: "\e961";
}
.liga-extinguisher:before {
  content: "\e962";
}
.liga-lighter:before {
  content: "\e963";
}
.liga-pocket-knife:before {
  content: "\e964";
}
.liga-tissue:before {
  content: "\e965";
}
.liga-toilet-paper:before {
  content: "\e966";
}
.liga-paper-towel:before {
  content: "\e967";
}
.liga-poop:before {
  content: "\e968";
}
.liga-umbrella:before {
  content: "\e969";
}
.liga-umbrella-rain:before {
  content: "\e96a";
}
.liga-droplets:before {
  content: "\e96b";
}
.liga-power:before {
  content: "\e96c";
}
.liga-volcano:before {
  content: "\e96d";
}
.liga-gas-mask:before {
  content: "\e96e";
}
.liga-tornado:before {
  content: "\e96f";
}
.liga-wind:before {
  content: "\e970";
}
.liga-fan:before {
  content: "\e971";
}
.liga-fan2:before {
  content: "\e972";
}
.liga-fan3:before {
  content: "\e973";
}
.liga-contrast:before {
  content: "\e974";
}
.liga-brightness:before {
  content: "\e975";
}
.liga-brightness-auto:before {
  content: "\e976";
}
.liga-brightness-auto2:before {
  content: "\e977";
}
.liga-brightness-contrast:before {
  content: "\e978";
}
.liga-sun:before {
  content: "\e979";
}
.liga-moon:before {
  content: "\e97a";
}
.liga-cloud:before {
  content: "\e97b";
}
.liga-cloud-rain:before {
  content: "\e97c";
}
.liga-cloud-rain2:before {
  content: "\e97d";
}
.liga-cloud-rain3:before {
  content: "\e97e";
}
.liga-cloud-snow:before {
  content: "\e97f";
}
.liga-cloud-snow2:before {
  content: "\e980";
}
.liga-sun-wind:before {
  content: "\e981";
}
.liga-cloud-wind:before {
  content: "\e982";
}
.liga-sun-fog:before {
  content: "\e983";
}
.liga-cloud-fog:before {
  content: "\e984";
}
.liga-cloud-sun:before {
  content: "\e985";
}
.liga-cloud-bolt:before {
  content: "\e986";
}
.liga-cloud-upload:before {
  content: "\e987";
}
.liga-cloud-download:before {
  content: "\e988";
}
.liga-cloud-sync:before {
  content: "\e989";
}
.liga-cloud-backup:before {
  content: "\e98a";
}
.liga-cloud-lock:before {
  content: "\e98b";
}
.liga-cloud-settings:before {
  content: "\e98c";
}
.liga-cloud-warning:before {
  content: "\e98d";
}
.liga-cloud-time:before {
  content: "\e98e";
}
.liga-cloud-check:before {
  content: "\e98f";
}
.liga-cloud-cross:before {
  content: "\e990";
}
.liga-cloud-connected:before {
  content: "\e991";
}
.liga-cloud-database:before {
  content: "\e992";
}
.liga-cloud-music:before {
  content: "\e993";
}
.liga-cloud-music2:before {
  content: "\e994";
}
.liga-cloud-message:before {
  content: "\e995";
}
.liga-branches:before {
  content: "\e996";
}
.liga-database:before {
  content: "\e997";
}
.liga-database-plus:before {
  content: "\e998";
}
.liga-database-minus:before {
  content: "\e999";
}
.liga-database-lock:before {
  content: "\e99a";
}
.liga-database-sync:before {
  content: "\e99b";
}
.liga-database-backup:before {
  content: "\e99c";
}
.liga-database-check:before {
  content: "\e99d";
}
.liga-database-time:before {
  content: "\e99e";
}
.liga-database-upload:before {
  content: "\e99f";
}
.liga-database-download:before {
  content: "\e9a0";
}
.liga-shield:before {
  content: "\e9a1";
}
.liga-shield-plus:before {
  content: "\e9a2";
}
.liga-shield-warning:before {
  content: "\e9a3";
}
.liga-shield-check:before {
  content: "\e9a4";
}
.liga-shield-cross:before {
  content: "\e9a5";
}
.liga-shield-sync:before {
  content: "\e9a6";
}
.liga-shield-info:before {
  content: "\e9a7";
}
.liga-shield-web:before {
  content: "\e9a8";
}
.liga-shield-user:before {
  content: "\e9a9";
}
.liga-shield-bolt:before {
  content: "\e9aa";
}
.liga-shield-droplet:before {
  content: "\e9ab";
}
.liga-shield-star:before {
  content: "\e9ac";
}
.liga-refresh-locked:before {
  content: "\e9ad";
}
.liga-lock:before {
  content: "\e9ae";
}
.liga-unlock:before {
  content: "\e9af";
}
.liga-unlock2:before {
  content: "\e9b0";
}
.liga-lock-check:before {
  content: "\e9b1";
}
.liga-lock-cross:before {
  content: "\e9b2";
}
.liga-lock-warning:before {
  content: "\e9b3";
}
.liga-lock2:before {
  content: "\e9b4";
}
.liga-unlock3:before {
  content: "\e9b5";
}
.liga-unlock4:before {
  content: "\e9b6";
}
.liga-lock-wireless:before {
  content: "\e9b7";
}
.liga-key:before {
  content: "\e9b8";
}
.liga-key2:before {
  content: "\e9b9";
}
.liga-key-hole:before {
  content: "\e9ba";
}
.liga-toggle-off:before {
  content: "\e9bb";
}
.liga-toggle-on:before {
  content: "\e9bc";
}
.liga-toggle-on2:before {
  content: "\e9bd";
}
.liga-toggle-off2:before {
  content: "\e9be";
}
.liga-switch-off:before {
  content: "\e9bf";
}
.liga-switch-on:before {
  content: "\e9c0";
}
.liga-equalizer:before {
  content: "\e9c1";
}
.liga-settings-icons:before {
  content: "\e9c2";
}
.liga-gear:before {
  content: "\e9c3";
}
.liga-gear2:before {
  content: "\e9c4";
}
.liga-gear3:before {
  content: "\e9c5";
}
.liga-wrench:before {
  content: "\e9c6";
}
.liga-wrench2:before {
  content: "\e9c7";
}
.liga-screwdriver:before {
  content: "\e9c8";
}
.liga-hammer:before {
  content: "\e9c9";
}
.liga-anvil:before {
  content: "\e9ca";
}
.liga-hammer-wrench:before {
  content: "\e9cb";
}
.liga-wrench-screwdriver:before {
  content: "\e9cc";
}
.liga-saw:before {
  content: "\e9cd";
}
.liga-axe:before {
  content: "\e9ce";
}
.liga-axe2:before {
  content: "\e9cf";
}
.liga-trowel:before {
  content: "\e9d0";
}
.liga-shovel:before {
  content: "\e9d1";
}
.liga-pickaxe:before {
  content: "\e9d2";
}
.liga-factory:before {
  content: "\e9d3";
}
.liga-robot-arm:before {
  content: "\e9d4";
}
.liga-conveyor:before {
  content: "\e9d5";
}
.liga-recycle:before {
  content: "\e9d6";
}
.liga-recycle2:before {
  content: "\e9d7";
}
.liga-trash:before {
  content: "\e9d8";
}
.liga-trash-cross:before {
  content: "\e9d9";
}
.liga-trash-full:before {
  content: "\e9da";
}
.liga-trash-warning:before {
  content: "\e9db";
}
.liga-trash-cross2:before {
  content: "\e9dc";
}
.liga-disposal:before {
  content: "\e9dd";
}
.liga-game:before {
  content: "\e9de";
}
.liga-gamepad:before {
  content: "\e9df";
}
.liga-dice:before {
  content: "\e9e0";
}
.liga-spades:before {
  content: "\e9e1";
}
.liga-spades2:before {
  content: "\e9e2";
}
.liga-diamonds:before {
  content: "\e9e3";
}
.liga-diamonds2:before {
  content: "\e9e4";
}
.liga-clubs:before {
  content: "\e9e5";
}
.liga-clubs2:before {
  content: "\e9e6";
}
.liga-heart:before {
  content: "\e9e7";
}
.liga-heart-shiny:before {
  content: "\e9e8";
}
.liga-star:before {
  content: "\e9e9";
}
.liga-star-half:before {
  content: "\e9ea";
}
.liga-star-empty:before {
  content: "\e9eb";
}
.liga-flag:before {
  content: "\e9ec";
}
.liga-flag2:before {
  content: "\e9ed";
}
.liga-flag3:before {
  content: "\e9ee";
}
.liga-mailbox-empty:before {
  content: "\e9ef";
}
.liga-mailbox-full:before {
  content: "\e9f0";
}
.liga-at-sign:before {
  content: "\e9f1";
}
.liga-envelope:before {
  content: "\e9f2";
}
.liga-envelope-open:before {
  content: "\e9f3";
}
.liga-envelope-open2:before {
  content: "\e9f4";
}
.liga-envelope-plus:before {
  content: "\e9f5";
}
.liga-envelope-warning:before {
  content: "\e9f6";
}
.liga-envelope-check:before {
  content: "\e9f7";
}
.liga-envelope-cross:before {
  content: "\e9f8";
}
.liga-paper-plane:before {
  content: "\e9f9";
}
.liga-paperclip:before {
  content: "\e9fa";
}
.liga-reply:before {
  content: "\e9fb";
}
.liga-reply-all:before {
  content: "\e9fc";
}
.liga-drawer:before {
  content: "\e9fd";
}
.liga-drawer-download:before {
  content: "\e9fe";
}
.liga-drawer-upload:before {
  content: "\e9ff";
}
.liga-drawer-full:before {
  content: "\ea00";
}
.liga-drawer-paper:before {
  content: "\ea01";
}
.liga-drawer-warning:before {
  content: "\ea02";
}
.liga-drawer-sync:before {
  content: "\ea03";
}
.liga-box:before {
  content: "\ea04";
}
.liga-drawer-full2:before {
  content: "\ea05";
}
.liga-drawer-paper2:before {
  content: "\ea06";
}
.liga-dresser:before {
  content: "\ea07";
}
.liga-dresser2:before {
  content: "\ea08";
}
.liga-cabinet:before {
  content: "\ea09";
}
.liga-eye:before {
  content: "\ea0a";
}
.liga-eye-cross:before {
  content: "\ea0b";
}
.liga-eye-plus:before {
  content: "\ea0c";
}
.liga-eye-minus:before {
  content: "\ea0d";
}
.liga-eye-droplet:before {
  content: "\ea0e";
}
.liga-eye2:before {
  content: "\ea0f";
}
.liga-binoculars:before {
  content: "\ea10";
}
.liga-binoculars2:before {
  content: "\ea11";
}
.liga-telescope:before {
  content: "\ea12";
}
.liga-server:before {
  content: "\ea13";
}
.liga-install:before {
  content: "\ea14";
}
.liga-upload:before {
  content: "\ea15";
}
.liga-hard-disk:before {
  content: "\ea16";
}
.liga-hard-disk-warning:before {
  content: "\ea17";
}
.liga-floppy-disk:before {
  content: "\ea18";
}
.liga-disc:before {
  content: "\ea19";
}
.liga-disc-audio:before {
  content: "\ea1a";
}
.liga-disc-play:before {
  content: "\ea1b";
}
.liga-cassette:before {
  content: "\ea1c";
}
.liga-printer:before {
  content: "\ea1d";
}
.liga-plotter:before {
  content: "\ea1e";
}
.liga-printer-3d:before {
  content: "\ea1f";
}
.liga-shredder:before {
  content: "\ea20";
}
.liga-file-empty:before {
  content: "\ea21";
}
.liga-file-text:before {
  content: "\ea22";
}
.liga-file-plus:before {
  content: "\ea23";
}
.liga-file-check:before {
  content: "\ea24";
}
.liga-file-lock:before {
  content: "\ea25";
}
.liga-file-user:before {
  content: "\ea26";
}
.liga-file-pencil:before {
  content: "\ea27";
}
.liga-files:before {
  content: "\ea28";
}
.liga-files2:before {
  content: "\ea29";
}
.liga-files-compare:before {
  content: "\ea2a";
}
.liga-folder:before {
  content: "\ea2b";
}
.liga-folder-history:before {
  content: "\ea2c";
}
.liga-folder-search:before {
  content: "\ea2d";
}
.liga-folder-home:before {
  content: "\ea2e";
}
.liga-folder-plus:before {
  content: "\ea2f";
}
.liga-folder-minus:before {
  content: "\ea30";
}
.liga-folder-zip:before {
  content: "\ea31";
}
.liga-folder-lock:before {
  content: "\ea32";
}
.liga-folder-download:before {
  content: "\ea33";
}
.liga-folder-upload:before {
  content: "\ea34";
}
.liga-folder-star:before {
  content: "\ea35";
}
.liga-folder-heart:before {
  content: "\ea36";
}
.liga-folder-user:before {
  content: "\ea37";
}
.liga-folder-share:before {
  content: "\ea38";
}
.liga-folder-code:before {
  content: "\ea39";
}
.liga-folder-picture:before {
  content: "\ea3a";
}
.liga-folder-camera:before {
  content: "\ea3b";
}
.liga-folder-music:before {
  content: "\ea3c";
}
.liga-folder-music2:before {
  content: "\ea3d";
}
.liga-folder-film:before {
  content: "\ea3e";
}
.liga-folder-video:before {
  content: "\ea3f";
}
.liga-scissors:before {
  content: "\ea40";
}
.liga-clipboard-empty:before {
  content: "\ea41";
}
.liga-clipboard-text:before {
  content: "\ea42";
}
.liga-paste:before {
  content: "\ea43";
}
.liga-paste2:before {
  content: "\ea44";
}
.liga-clipboard-check:before {
  content: "\ea45";
}
.liga-clipboard-down:before {
  content: "\ea46";
}
.liga-clipboard-left:before {
  content: "\ea47";
}
.liga-clipboard-warning:before {
  content: "\ea48";
}
.liga-clipboard-user:before {
  content: "\ea49";
}
.liga-clipboard-pencil:before {
  content: "\ea4a";
}
.liga-checklist:before {
  content: "\ea4b";
}
.liga-clipboard-list:before {
  content: "\ea4c";
}
.liga-clipboard-chart:before {
  content: "\ea4d";
}
.liga-register:before {
  content: "\ea4e";
}
.liga-enter:before {
  content: "\ea4f";
}
.liga-exit:before {
  content: "\ea50";
}
.liga-news:before {
  content: "\ea51";
}
.liga-read:before {
  content: "\ea52";
}
.liga-newsletter:before {
  content: "\ea53";
}
.liga-newsletters:before {
  content: "\ea54";
}
.liga-paper:before {
  content: "\ea55";
}
.liga-graduation:before {
  content: "\ea56";
}
.liga-certificate:before {
  content: "\ea57";
}
.liga-certificate2:before {
  content: "\ea58";
}
.liga-medal-empty:before {
  content: "\ea59";
}
.liga-medal-first:before {
  content: "\ea5a";
}
.liga-medal-second:before {
  content: "\ea5b";
}
.liga-medal-third:before {
  content: "\ea5c";
}
.liga-podium:before {
  content: "\ea5d";
}
.liga-trophy:before {
  content: "\ea5e";
}
.liga-trophy-first:before {
  content: "\ea5f";
}
.liga-trophy-second:before {
  content: "\ea60";
}
.liga-trophy-third:before {
  content: "\ea61";
}
.liga-music-note:before {
  content: "\ea62";
}
.liga-music-note2:before {
  content: "\ea63";
}
.liga-music-note-plus:before {
  content: "\ea64";
}
.liga-music-note3:before {
  content: "\ea65";
}
.liga-music-note4:before {
  content: "\ea66";
}
.liga-music-note5:before {
  content: "\ea67";
}
.liga-music-note6:before {
  content: "\ea68";
}
.liga-playlist:before {
  content: "\ea69";
}
.liga-list-heart:before {
  content: "\ea6a";
}
.liga-cable:before {
  content: "\ea6b";
}
.liga-turntable:before {
  content: "\ea6c";
}
.liga-piano:before {
  content: "\ea6d";
}
.liga-guitar:before {
  content: "\ea6e";
}
.liga-guitar-electric:before {
  content: "\ea6f";
}
.liga-guitar-pick:before {
  content: "\ea70";
}
.liga-metronome:before {
  content: "\ea71";
}
.liga-tuning-fork:before {
  content: "\ea72";
}
.liga-album:before {
  content: "\ea73";
}
.liga-playlist2:before {
  content: "\ea74";
}
.liga-photo-album:before {
  content: "\ea75";
}
.liga-shuffle:before {
  content: "\ea76";
}
.liga-repeat:before {
  content: "\ea77";
}
.liga-repeat-infinite:before {
  content: "\ea78";
}
.liga-repeat-once:before {
  content: "\ea79";
}
.liga-earphones:before {
  content: "\ea7a";
}
.liga-headphones:before {
  content: "\ea7b";
}
.liga-headset:before {
  content: "\ea7c";
}
.liga-headphones-warning:before {
  content: "\ea7d";
}
.liga-headphones-cross:before {
  content: "\ea7e";
}
.liga-loudspeaker:before {
  content: "\ea7f";
}
.liga-equalizer2:before {
  content: "\ea80";
}
.liga-theater:before {
  content: "\ea81";
}
.liga-3d-glasses:before {
  content: "\ea82";
}
.liga-ticket:before {
  content: "\ea83";
}
.liga-megaphone:before {
  content: "\ea84";
}
.liga-clapboard:before {
  content: "\ea85";
}
.liga-clapboard-play:before {
  content: "\ea86";
}
.liga-reel:before {
  content: "\ea87";
}
.liga-film:before {
  content: "\ea88";
}
.liga-film-play:before {
  content: "\ea89";
}
.liga-film-play2:before {
  content: "\ea8a";
}
.liga-projector-screen-collapsed:before {
  content: "\ea8b";
}
.liga-projector-screen-play:before {
  content: "\ea8c";
}
.liga-projector-screen-chart:before {
  content: "\ea8d";
}
.liga-surveillance:before {
  content: "\ea8e";
}
.liga-surveillance2:before {
  content: "\ea8f";
}
.liga-camera:before {
  content: "\ea90";
}
.liga-camera-cross:before {
  content: "\ea91";
}
.liga-camera-play:before {
  content: "\ea92";
}
.liga-camera-pause:before {
  content: "\ea93";
}
.liga-camera-stop:before {
  content: "\ea94";
}
.liga-camera-record:before {
  content: "\ea95";
}
.liga-camera-time:before {
  content: "\ea96";
}
.liga-camera2:before {
  content: "\ea97";
}
.liga-camera3:before {
  content: "\ea98";
}
.liga-camera-time2:before {
  content: "\ea99";
}
.liga-camera-flip:before {
  content: "\ea9a";
}
.liga-camera-stabilization:before {
  content: "\ea9b";
}
.liga-panorama:before {
  content: "\ea9c";
}
.liga-shutter:before {
  content: "\ea9d";
}
.liga-face-detection:before {
  content: "\ea9e";
}
.liga-convex:before {
  content: "\ea9f";
}
.liga-concave:before {
  content: "\eaa0";
}
.liga-picture:before {
  content: "\eaa1";
}
.liga-picture2:before {
  content: "\eaa2";
}
.liga-book:before {
  content: "\eaa3";
}
.liga-book-audio:before {
  content: "\eaa4";
}
.liga-book2:before {
  content: "\eaa5";
}
.liga-book3:before {
  content: "\eaa6";
}
.liga-book-bookmarked:before {
  content: "\eaa7";
}
.liga-book-bookmarked2:before {
  content: "\eaa8";
}
.liga-bookmark:before {
  content: "\eaa9";
}
.liga-bookmark-move:before {
  content: "\eaaa";
}
.liga-bookmark-plus:before {
  content: "\eaab";
}
.liga-bookmark-minus:before {
  content: "\eaac";
}
.liga-label:before {
  content: "\eaad";
}
.liga-books:before {
  content: "\eaae";
}
.liga-library:before {
  content: "\eaaf";
}
.liga-contacts:before {
  content: "\eab0";
}
.liga-business-card:before {
  content: "\eab1";
}
.liga-portrait:before {
  content: "\eab2";
}
.liga-portrait2:before {
  content: "\eab3";
}
.liga-user:before {
  content: "\eab4";
}
.liga-user-plus:before {
  content: "\eab5";
}
.liga-user-minus:before {
  content: "\eab6";
}
.liga-user-heart:before {
  content: "\eab7";
}
.liga-user-lock:before {
  content: "\eab8";
}
.liga-user-question:before {
  content: "\eab9";
}
.liga-user-bubble:before {
  content: "\eaba";
}
.liga-user-check:before {
  content: "\eabb";
}
.liga-user-cross:before {
  content: "\eabc";
}
.liga-user-edit:before {
  content: "\eabd";
}
.liga-user-gear:before {
  content: "\eabe";
}
.liga-user-info:before {
  content: "\eabf";
}
.liga-user-24:before {
  content: "\eac0";
}
.liga-support:before {
  content: "\eac1";
}
.liga-group:before {
  content: "\eac2";
}
.liga-group-plus:before {
  content: "\eac3";
}
.liga-group-minus:before {
  content: "\eac4";
}
.liga-group-edit:before {
  content: "\eac5";
}
.liga-group-circle:before {
  content: "\eac6";
}
.liga-head:before {
  content: "\eac7";
}
.liga-head-hearing:before {
  content: "\eac8";
}
.liga-head-speech:before {
  content: "\eac9";
}
.liga-head-question:before {
  content: "\eaca";
}
.liga-head-money:before {
  content: "\eacb";
}
.liga-head-processing:before {
  content: "\eacc";
}
.liga-head-bubble:before {
  content: "\eacd";
}
.liga-portrait-male:before {
  content: "\eace";
}
.liga-portrait-female:before {
  content: "\eacf";
}
.liga-baby:before {
  content: "\ead0";
}
.liga-baby-crying:before {
  content: "\ead1";
}
.liga-baby-smile:before {
  content: "\ead2";
}
.liga-baby-tongue:before {
  content: "\ead3";
}
.liga-baby2:before {
  content: "\ead4";
}
.liga-baby-crying2:before {
  content: "\ead5";
}
.liga-baby3:before {
  content: "\ead6";
}
.liga-baby-tongue2:before {
  content: "\ead7";
}
.liga-pacifier:before {
  content: "\ead8";
}
.liga-baby-bottle:before {
  content: "\ead9";
}
.liga-stroller:before {
  content: "\eada";
}
.liga-standing:before {
  content: "\eadb";
}
.liga-walking:before {
  content: "\eadc";
}
.liga-waving:before {
  content: "\eadd";
}
.liga-victory:before {
  content: "\eade";
}
.liga-running:before {
  content: "\eadf";
}
.liga-jumping:before {
  content: "\eae0";
}
.liga-traveling:before {
  content: "\eae1";
}
.liga-stairs:before {
  content: "\eae2";
}
.liga-escalator-up:before {
  content: "\eae3";
}
.liga-escalator-down:before {
  content: "\eae4";
}
.liga-depressed:before {
  content: "\eae5";
}
.liga-male:before {
  content: "\eae6";
}
.liga-female:before {
  content: "\eae7";
}
.liga-gender:before {
  content: "\eae8";
}
.liga-gender2:before {
  content: "\eae9";
}
.liga-bathroom-scale:before {
  content: "\eaea";
}
.liga-height:before {
  content: "\eaeb";
}
.liga-button:before {
  content: "\eaec";
}
.liga-button2:before {
  content: "\eaed";
}
.liga-suit:before {
  content: "\eaee";
}
.liga-bow-tie:before {
  content: "\eaef";
}
.liga-necktie:before {
  content: "\eaf0";
}
.liga-sock:before {
  content: "\eaf1";
}
.liga-sock-dirty:before {
  content: "\eaf2";
}
.liga-shoe:before {
  content: "\eaf3";
}
.liga-high-heels:before {
  content: "\eaf4";
}
.liga-lips:before {
  content: "\eaf5";
}
.liga-lipstick:before {
  content: "\eaf6";
}
.liga-hairdryer:before {
  content: "\eaf7";
}
.liga-hand-soap:before {
  content: "\eaf8";
}
.liga-ring:before {
  content: "\eaf9";
}
.liga-mirror:before {
  content: "\eafa";
}
.liga-bowler-hat:before {
  content: "\eafb";
}
.liga-top-hat:before {
  content: "\eafc";
}
.liga-pants:before {
  content: "\eafd";
}
.liga-shorts:before {
  content: "\eafe";
}
.liga-underwear:before {
  content: "\eaff";
}
.liga-underwear2:before {
  content: "\eb00";
}
.liga-bra:before {
  content: "\eb01";
}
.liga-shirt:before {
  content: "\eb02";
}
.liga-gown:before {
  content: "\eb03";
}
.liga-hoodie:before {
  content: "\eb04";
}
.liga-hanger:before {
  content: "\eb05";
}
.liga-hanger2:before {
  content: "\eb06";
}
.liga-washing-machine:before {
  content: "\eb07";
}
.liga-laundry-time:before {
  content: "\eb08";
}
.liga-machine-wash:before {
  content: "\eb09";
}
.liga-machine-wash-cold:before {
  content: "\eb0a";
}
.liga-machine-wash-warm:before {
  content: "\eb0b";
}
.liga-machine-wash-hot:before {
  content: "\eb0c";
}
.liga-iron:before {
  content: "\eb0d";
}
.liga-iron-low:before {
  content: "\eb0e";
}
.liga-iron-medium:before {
  content: "\eb0f";
}
.liga-iron-high:before {
  content: "\eb10";
}
.liga-iron-no:before {
  content: "\eb11";
}
.liga-iron-steam:before {
  content: "\eb12";
}
.liga-iron-steam-no:before {
  content: "\eb13";
}
.liga-store:before {
  content: "\eb14";
}
.liga-store-24:before {
  content: "\eb15";
}
.liga-barcode:before {
  content: "\eb16";
}
.liga-barcode2:before {
  content: "\eb17";
}
.liga-barcode3:before {
  content: "\eb18";
}
.liga-qrcode:before {
  content: "\eb19";
}
.liga-box2:before {
  content: "\eb1a";
}
.liga-box-check:before {
  content: "\eb1b";
}
.liga-box-cross:before {
  content: "\eb1c";
}
.liga-box3:before {
  content: "\eb1d";
}
.liga-box-check2:before {
  content: "\eb1e";
}
.liga-box-cross2:before {
  content: "\eb1f";
}
.liga-basket:before {
  content: "\eb20";
}
.liga-bag:before {
  content: "\eb21";
}
.liga-bag-full:before {
  content: "\eb22";
}
.liga-bag-plus:before {
  content: "\eb23";
}
.liga-bag-minus:before {
  content: "\eb24";
}
.liga-bag-checkout:before {
  content: "\eb25";
}
.liga-bag-check:before {
  content: "\eb26";
}
.liga-bag-cross:before {
  content: "\eb27";
}
.liga-cart:before {
  content: "\eb28";
}
.liga-cart-full:before {
  content: "\eb29";
}
.liga-cart-plus:before {
  content: "\eb2a";
}
.liga-cart-minus:before {
  content: "\eb2b";
}
.liga-cart-load:before {
  content: "\eb2c";
}
.liga-cart-unload:before {
  content: "\eb2d";
}
.liga-cart-manage:before {
  content: "\eb2e";
}
.liga-cart-checkout:before {
  content: "\eb2f";
}
.liga-cart-check:before {
  content: "\eb30";
}
.liga-cart-cross:before {
  content: "\eb31";
}
.liga-tag:before {
  content: "\eb32";
}
.liga-tag2:before {
  content: "\eb33";
}
.liga-receipt:before {
  content: "\eb34";
}
.liga-wallet:before {
  content: "\eb35";
}
.liga-debit-card:before {
  content: "\eb36";
}
.liga-piggy-bank:before {
  content: "\eb37";
}
.liga-money-pouch:before {
  content: "\eb38";
}
.liga-money-pouch-euro:before {
  content: "\eb39";
}
.liga-money-pouch-pound:before {
  content: "\eb3a";
}
.liga-money-pouch-yen:before {
  content: "\eb3b";
}
.liga-money-pouch-ruble:before {
  content: "\eb3c";
}
.liga-bill:before {
  content: "\eb3d";
}
.liga-bill2:before {
  content: "\eb3e";
}
.liga-bill-dollar:before {
  content: "\eb3f";
}
.liga-bill-euro:before {
  content: "\eb40";
}
.liga-bill-pound:before {
  content: "\eb41";
}
.liga-bill-yen:before {
  content: "\eb42";
}
.liga-bill-ruble:before {
  content: "\eb43";
}
.liga-bill-stack:before {
  content: "\eb44";
}
.liga-bill-stack-dollar:before {
  content: "\eb45";
}
.liga-bill-stack-euro:before {
  content: "\eb46";
}
.liga-bill-stack-pound:before {
  content: "\eb47";
}
.liga-bill-stack-yen:before {
  content: "\eb48";
}
.liga-bill-stack-ruble:before {
  content: "\eb49";
}
.liga-calculator:before {
  content: "\eb4a";
}
.liga-calculator2:before {
  content: "\eb4b";
}
.liga-safe:before {
  content: "\eb4c";
}
.liga-telephone:before {
  content: "\eb4d";
}
.liga-phone-end:before {
  content: "\eb4e";
}
.liga-missed-incoming:before {
  content: "\eb4f";
}
.liga-missed-outgoing:before {
  content: "\eb50";
}
.liga-phone-lock:before {
  content: "\eb51";
}
.liga-phone-loudspeaker:before {
  content: "\eb52";
}
.liga-phone-bubble:before {
  content: "\eb53";
}
.liga-phone-pause:before {
  content: "\eb54";
}
.liga-phone-outgoing:before {
  content: "\eb55";
}
.liga-phone-incoming:before {
  content: "\eb56";
}
.liga-phone-in-out:before {
  content: "\eb57";
}
.liga-phone-cross:before {
  content: "\eb58";
}
.liga-phone-user:before {
  content: "\eb59";
}
.liga-phone-time:before {
  content: "\eb5a";
}
.liga-phone-24:before {
  content: "\eb5b";
}
.liga-phone-sip:before {
  content: "\eb5c";
}
.liga-phone-plus:before {
  content: "\eb5d";
}
.liga-phone-minus:before {
  content: "\eb5e";
}
.liga-voicemail:before {
  content: "\eb5f";
}
.liga-telephone2:before {
  content: "\eb60";
}
.liga-telephone3:before {
  content: "\eb61";
}
.liga-pushpin:before {
  content: "\eb62";
}
.liga-pushpin-cross:before {
  content: "\eb63";
}
.liga-pushpin2:before {
  content: "\eb64";
}
.liga-map-marker:before {
  content: "\eb65";
}
.liga-map-marker-user:before {
  content: "\eb66";
}
.liga-map-marker-down:before {
  content: "\eb67";
}
.liga-map-marker-plus:before {
  content: "\eb68";
}
.liga-map-marker-minus:before {
  content: "\eb69";
}
.liga-map-marker-check:before {
  content: "\eb6a";
}
.liga-map-marker-cross:before {
  content: "\eb6b";
}
.liga-map-marker-edit:before {
  content: "\eb6c";
}
.liga-helm:before {
  content: "\eb6d";
}
.liga-compass2:before {
  content: "\eb6e";
}
.liga-map:before {
  content: "\eb6f";
}
.liga-map2:before {
  content: "\eb70";
}
.liga-compass3:before {
  content: "\eb71";
}
.liga-road-sign:before {
  content: "\eb72";
}
.liga-calendar:before {
  content: "\eb73";
}
.liga-calendar2:before {
  content: "\eb74";
}
.liga-calendar3:before {
  content: "\eb75";
}
.liga-calendar4:before {
  content: "\eb76";
}
.liga-calendar-time:before {
  content: "\eb77";
}
.liga-calendar-check:before {
  content: "\eb78";
}
.liga-calendar-cross:before {
  content: "\eb79";
}
.liga-calendar-plus:before {
  content: "\eb7a";
}
.liga-calendar-minus:before {
  content: "\eb7b";
}
.liga-calendar-user:before {
  content: "\eb7c";
}
.liga-mouse:before {
  content: "\eb7d";
}
.liga-mouse-wireless:before {
  content: "\eb7e";
}
.liga-mouse-click-left:before {
  content: "\eb7f";
}
.liga-mouse-click-right:before {
  content: "\eb80";
}
.liga-mouse-click-both:before {
  content: "\eb81";
}
.liga-mouse-network:before {
  content: "\eb82";
}
.liga-mouse-check:before {
  content: "\eb83";
}
.liga-mouse-cross:before {
  content: "\eb84";
}
.liga-keyboard:before {
  content: "\eb85";
}
.liga-keyboard-wireless:before {
  content: "\eb86";
}
.liga-keyboard-show:before {
  content: "\eb87";
}
.liga-keyboard-hide:before {
  content: "\eb88";
}
.liga-keyboard-key:before {
  content: "\eb89";
}
.liga-keyboard-key2:before {
  content: "\eb8a";
}
.liga-keyboard-key-down:before {
  content: "\eb8b";
}
.liga-keyboard-key-up:before {
  content: "\eb8c";
}
.liga-delete:before {
  content: "\eb8d";
}
.liga-spell-check:before {
  content: "\eb8e";
}
.liga-circle-extract:before {
  content: "\eb8f";
}
.liga-circle-insert:before {
  content: "\eb90";
}
.liga-widescreen:before {
  content: "\eb91";
}
.liga-widescreen-play:before {
  content: "\eb92";
}
.liga-widescreen-user:before {
  content: "\eb93";
}
.liga-signal:before {
  content: "\eb94";
}
.liga-signal-lock:before {
  content: "\eb95";
}
.liga-signal-3of4:before {
  content: "\eb96";
}
.liga-signal-2of4:before {
  content: "\eb97";
}
.liga-signal-1of4:before {
  content: "\eb98";
}
.liga-signal-error:before {
  content: "\eb99";
}
.liga-sim:before {
  content: "\eb9a";
}
.liga-microsd:before {
  content: "\eb9b";
}
.liga-microsd-plus:before {
  content: "\eb9c";
}
.liga-usb-drive:before {
  content: "\eb9d";
}
.liga-usb:before {
  content: "\eb9e";
}
.liga-usb-question:before {
  content: "\eb9f";
}
.liga-usb-warning:before {
  content: "\eba0";
}
.liga-usb-check:before {
  content: "\eba1";
}
.liga-usb-cross:before {
  content: "\eba2";
}
.liga-usb-plus:before {
  content: "\eba3";
}
.liga-usb-minus:before {
  content: "\eba4";
}
.liga-smartphone:before {
  content: "\eba5";
}
.liga-smartphone2:before {
  content: "\eba6";
}
.liga-smartphone-vibration:before {
  content: "\eba7";
}
.liga-smartphone-speakers:before {
  content: "\eba8";
}
.liga-smartphone-pencil:before {
  content: "\eba9";
}
.liga-smartphone-ringtone:before {
  content: "\ebaa";
}
.liga-smartphone-silent:before {
  content: "\ebab";
}
.liga-smartphone-time:before {
  content: "\ebac";
}
.liga-smartphone-sync:before {
  content: "\ebad";
}
.liga-smartphone-code:before {
  content: "\ebae";
}
.liga-smartphone-backup:before {
  content: "\ebaf";
}
.liga-smartphone-info:before {
  content: "\ebb0";
}
.liga-smartphone-lock:before {
  content: "\ebb1";
}
.liga-smartphone-check:before {
  content: "\ebb2";
}
.liga-smartphone-warning:before {
  content: "\ebb3";
}
.liga-smartphone-text:before {
  content: "\ebb4";
}
.liga-smartphone-user:before {
  content: "\ebb5";
}
.liga-smartphone-rotate:before {
  content: "\ebb6";
}
.liga-smartphone-transfer:before {
  content: "\ebb7";
}
.liga-tablet:before {
  content: "\ebb8";
}
.liga-tablet2:before {
  content: "\ebb9";
}
.liga-laptop:before {
  content: "\ebba";
}
.liga-laptop-cross:before {
  content: "\ebbb";
}
.liga-laptop-warning:before {
  content: "\ebbc";
}
.liga-laptop-play:before {
  content: "\ebbd";
}
.liga-laptop-code:before {
  content: "\ebbe";
}
.liga-laptop-education:before {
  content: "\ebbf";
}
.liga-laptop-user:before {
  content: "\ebc0";
}
.liga-laptop-share:before {
  content: "\ebc1";
}
.liga-laptop-phone:before {
  content: "\ebc2";
}
.liga-laptop-phone-sync:before {
  content: "\ebc3";
}
.liga-webcam:before {
  content: "\ebc4";
}
.liga-monitor:before {
  content: "\ebc5";
}
.liga-monitor-play:before {
  content: "\ebc6";
}
.liga-monitor-share:before {
  content: "\ebc7";
}
.liga-thin:before {
  content: "\ebc8";
}
.liga-external:before {
  content: "\ebc9";
}
.liga-external2:before {
  content: "\ebca";
}
.liga-window2:before {
  content: "\ebcb";
}
.liga-window-tab:before {
  content: "\ebcc";
}
.liga-window-tab-plus:before {
  content: "\ebcd";
}
.liga-tv:before {
  content: "\ebce";
}
.liga-radio:before {
  content: "\ebcf";
}
.liga-remote-control:before {
  content: "\ebd0";
}
.liga-remote-control2:before {
  content: "\ebd1";
}
.liga-power-switch:before {
  content: "\ebd2";
}
.liga-power-switch2:before {
  content: "\ebd3";
}
.liga-power2:before {
  content: "\ebd4";
}
.liga-power-auto:before {
  content: "\ebd5";
}
.liga-power-cross:before {
  content: "\ebd6";
}
.liga-power-warning:before {
  content: "\ebd7";
}
.liga-power-dollar:before {
  content: "\ebd8";
}
.liga-lamp:before {
  content: "\ebd9";
}
.liga-lamp-bright:before {
  content: "\ebda";
}
.liga-lamp-check:before {
  content: "\ebdb";
}
.liga-lamp-plus:before {
  content: "\ebdc";
}
.liga-lamp-minus:before {
  content: "\ebdd";
}
.liga-lamp-time:before {
  content: "\ebde";
}
.liga-lamp-gear:before {
  content: "\ebdf";
}
.liga-flashlight:before {
  content: "\ebe0";
}
.liga-flashlight-bright:before {
  content: "\ebe1";
}
.liga-floor-lamp:before {
  content: "\ebe2";
}
.liga-reflection:before {
  content: "\ebe3";
}
.liga-solar-power:before {
  content: "\ebe4";
}
.liga-solar-power2:before {
  content: "\ebe5";
}
.liga-solar-panel:before {
  content: "\ebe6";
}
.liga-plug:before {
  content: "\ebe7";
}
.liga-plug-cross:before {
  content: "\ebe8";
}
.liga-socket:before {
  content: "\ebe9";
}
.liga-battery-bolt:before {
  content: "\ebea";
}
.liga-battery-plug:before {
  content: "\ebeb";
}
.liga-battery-droplet:before {
  content: "\ebec";
}
.liga-battery-plus:before {
  content: "\ebed";
}
.liga-battery-question:before {
  content: "\ebee";
}
.liga-battery-warning:before {
  content: "\ebef";
}
.liga-battery-check:before {
  content: "\ebf0";
}
.liga-battery-cross:before {
  content: "\ebf1";
}
.liga-battery-empty:before {
  content: "\ebf2";
}
.liga-battery-10:before {
  content: "\ebf3";
}
.liga-battery-25:before {
  content: "\ebf4";
}
.liga-battery-40:before {
  content: "\ebf5";
}
.liga-battery-50:before {
  content: "\ebf6";
}
.liga-battery-60:before {
  content: "\ebf7";
}
.liga-battery-75:before {
  content: "\ebf8";
}
.liga-battery-90:before {
  content: "\ebf9";
}
.liga-battery-100:before {
  content: "\ebfa";
}
.liga-battery-plug2:before {
  content: "\ebfb";
}
.liga-battery-plug-10:before {
  content: "\ebfc";
}
.liga-battery-plug-25:before {
  content: "\ebfd";
}
.liga-battery-plug-40:before {
  content: "\ebfe";
}
.liga-battery-plug-50:before {
  content: "\ebff";
}
.liga-battery-plug-60:before {
  content: "\ec00";
}
.liga-battery-plug-75:before {
  content: "\ec01";
}
.liga-battery-plug-90:before {
  content: "\ec02";
}
.liga-battery-plug-100:before {
  content: "\ec03";
}
.liga-led:before {
  content: "\ec04";
}
.liga-led-bright:before {
  content: "\ec05";
}
.liga-resistor:before {
  content: "\ec06";
}
.liga-capacitor:before {
  content: "\ec07";
}
.liga-ic:before {
  content: "\ec08";
}
.liga-cpu:before {
  content: "\ec09";
}
.liga-cpu-x64:before {
  content: "\ec0a";
}
.liga-cpu-x86:before {
  content: "\ec0b";
}
.liga-bubble-text:before {
  content: "\ec0c";
}
.liga-bubble-check:before {
  content: "\ec0d";
}
.liga-bubble-cross:before {
  content: "\ec0e";
}
.liga-bubble-time:before {
  content: "\ec0f";
}
.liga-bubbles:before {
  content: "\ec10";
}
.liga-bubble:before {
  content: "\ec11";
}
.liga-bubble-shiny:before {
  content: "\ec12";
}
.liga-bubbles2:before {
  content: "\ec13";
}
.liga-bubble-dots:before {
  content: "\ec14";
}
.liga-bubble-paperclip:before {
  content: "\ec15";
}
.liga-bubble-exclamation:before {
  content: "\ec16";
}
.liga-bubble-question:before {
  content: "\ec17";
}
.liga-bubble-text2:before {
  content: "\ec18";
}
.liga-bubble-writing:before {
  content: "\ec19";
}
.liga-bubble-picture:before {
  content: "\ec1a";
}
.liga-bubble-music:before {
  content: "\ec1b";
}
.liga-bubble-music2:before {
  content: "\ec1c";
}
.liga-bubble-video:before {
  content: "\ec1d";
}
.liga-bubble-friend:before {
  content: "\ec1e";
}
.liga-bubble-friend-plus:before {
  content: "\ec1f";
}
.liga-bubble-quote:before {
  content: "\ec20";
}
.liga-bubble-heart:before {
  content: "\ec21";
}
.liga-bubble-emoji:before {
  content: "\ec22";
}
.liga-bubble-poker-face:before {
  content: "\ec23";
}
.liga-bubble-sad:before {
  content: "\ec24";
}
.liga-bubble-mad:before {
  content: "\ec25";
}
.liga-bubble-shocked:before {
  content: "\ec26";
}
.liga-quote-left:before {
  content: "\ec27";
}
.liga-quote-right:before {
  content: "\ec28";
}
.liga-dna:before {
  content: "\ec29";
}
.liga-dna-code:before {
  content: "\ec2a";
}
.liga-lungs:before {
  content: "\ec2b";
}
.liga-inhaler:before {
  content: "\ec2c";
}
.liga-heart2:before {
  content: "\ec2d";
}
.liga-heart3:before {
  content: "\ec2e";
}
.liga-heart-plus:before {
  content: "\ec2f";
}
.liga-heart-time:before {
  content: "\ec30";
}
.liga-heart-pulse:before {
  content: "\ec31";
}
.liga-stethoscope:before {
  content: "\ec32";
}
.liga-pulse:before {
  content: "\ec33";
}
.liga-syringe:before {
  content: "\ec34";
}
.liga-bottle2:before {
  content: "\ec35";
}
.liga-pills:before {
  content: "\ec36";
}
.liga-first-aid-kit:before {
  content: "\ec37";
}
.liga-lifebuoy:before {
  content: "\ec38";
}
.liga-bandage:before {
  content: "\ec39";
}
.liga-bandages:before {
  content: "\ec3a";
}
.liga-thermometer:before {
  content: "\ec3b";
}
.liga-thermometer-plus:before {
  content: "\ec3c";
}
.liga-thermometer-minus:before {
  content: "\ec3d";
}
.liga-thermometer-sun:before {
  content: "\ec3e";
}
.liga-thermometer-snow:before {
  content: "\ec3f";
}
.liga-thermometer-gear:before {
  content: "\ec40";
}
.liga-microscope:before {
  content: "\ec41";
}
.liga-atom:before {
  content: "\ec42";
}
.liga-test-tube:before {
  content: "\ec43";
}
.liga-beaker:before {
  content: "\ec44";
}
.liga-rb-flask:before {
  content: "\ec45";
}
.liga-rb-flask-warning:before {
  content: "\ec46";
}
.liga-flask:before {
  content: "\ec47";
}
.liga-flask-warning:before {
  content: "\ec48";
}
.liga-skull:before {
  content: "\ec49";
}
.liga-bone:before {
  content: "\ec4a";
}
.liga-chart-upward:before {
  content: "\ec4b";
}
.liga-chart-downward:before {
  content: "\ec4c";
}
.liga-scatterplot:before {
  content: "\ec4d";
}
.liga-chart:before {
  content: "\ec4e";
}
.liga-pie-chart:before {
  content: "\ec4f";
}
.liga-cake:before {
  content: "\ec50";
}
.liga-gift:before {
  content: "\ec51";
}
.liga-balloon:before {
  content: "\ec52";
}
.liga-flower:before {
  content: "\ec53";
}
.liga-flower2:before {
  content: "\ec54";
}
.liga-flower3:before {
  content: "\ec55";
}
.liga-rank:before {
  content: "\ec56";
}
.liga-rank2:before {
  content: "\ec57";
}
.liga-rank3:before {
  content: "\ec58";
}
.liga-crown:before {
  content: "\ec59";
}
.liga-emerald:before {
  content: "\ec5a";
}
.liga-ruby:before {
  content: "\ec5b";
}
.liga-diamond:before {
  content: "\ec5c";
}
.liga-diamond2:before {
  content: "\ec5d";
}
.liga-tea-bag:before {
  content: "\ec5e";
}
.liga-teacup:before {
  content: "\ec5f";
}
.liga-glass:before {
  content: "\ec60";
}
.liga-glass-broken:before {
  content: "\ec61";
}
.liga-glass-cocktail:before {
  content: "\ec62";
}
.liga-tankard:before {
  content: "\ec63";
}
.liga-glass2:before {
  content: "\ec64";
}
.liga-fork:before {
  content: "\ec65";
}
.liga-knife:before {
  content: "\ec66";
}
.liga-spoon:before {
  content: "\ec67";
}
.liga-fork-knife:before {
  content: "\ec68";
}
.liga-fork-spoon:before {
  content: "\ec69";
}
.liga-fork-knife2:before {
  content: "\ec6a";
}
.liga-fork-spoon2:before {
  content: "\ec6b";
}
.liga-table:before {
  content: "\ec6c";
}
.liga-chef:before {
  content: "\ec6d";
}
.liga-pot:before {
  content: "\ec6e";
}
.liga-pot-time:before {
  content: "\ec6f";
}
.liga-pot-hot:before {
  content: "\ec70";
}
.liga-microwave:before {
  content: "\ec71";
}
.liga-refrigerator:before {
  content: "\ec72";
}
.liga-kitchen-scale:before {
  content: "\ec73";
}
.liga-weight:before {
  content: "\ec74";
}
.liga-weight-1kg:before {
  content: "\ec75";
}
.liga-weight-1lb:before {
  content: "\ec76";
}
.liga-weight-1oz:before {
  content: "\ec77";
}
.liga-mixer:before {
  content: "\ec78";
}
.liga-rolling-pin:before {
  content: "\ec79";
}
.liga-egg:before {
  content: "\ec7a";
}
.liga-egg-broken:before {
  content: "\ec7b";
}
.liga-platter:before {
  content: "\ec7c";
}
.liga-pig:before {
  content: "\ec7d";
}
.liga-steak:before {
  content: "\ec7e";
}
.liga-fries:before {
  content: "\ec7f";
}
.liga-burger:before {
  content: "\ec80";
}
.liga-hot-dog:before {
  content: "\ec81";
}
.liga-pizza:before {
  content: "\ec82";
}
.liga-chicken-leg:before {
  content: "\ec83";
}
.liga-fish:before {
  content: "\ec84";
}
.liga-carrot:before {
  content: "\ec85";
}
.liga-cheese:before {
  content: "\ec86";
}
.liga-fried-egg:before {
  content: "\ec87";
}
.liga-milk-bottle:before {
  content: "\ec88";
}
.liga-pitcher:before {
  content: "\ec89";
}
.liga-toaster:before {
  content: "\ec8a";
}
.liga-toast:before {
  content: "\ec8b";
}
.liga-ice-cream-bar:before {
  content: "\ec8c";
}
.liga-ice-cream:before {
  content: "\ec8d";
}
.liga-candy:before {
  content: "\ec8e";
}
.liga-lollipop:before {
  content: "\ec8f";
}
.liga-donut:before {
  content: "\ec90";
}
.liga-coffee-maker:before {
  content: "\ec91";
}
.liga-coffee-pot:before {
  content: "\ec92";
}
.liga-paper-cup:before {
  content: "\ec93";
}
.liga-mug:before {
  content: "\ec94";
}
.liga-coffee-bean:before {
  content: "\ec95";
}
.liga-cherry:before {
  content: "\ec96";
}
.liga-grape:before {
  content: "\ec97";
}
.liga-citrus:before {
  content: "\ec98";
}
.liga-apple:before {
  content: "\ec99";
}
.liga-apple-check:before {
  content: "\ec9a";
}
.liga-leaf:before {
  content: "\ec9b";
}
.liga-leaf-plus:before {
  content: "\ec9c";
}
.liga-leaf-check:before {
  content: "\ec9d";
}
.liga-leaf-cross:before {
  content: "\ec9e";
}
.liga-cactus:before {
  content: "\ec9f";
}
.liga-diving-mask:before {
  content: "\eca0";
}
.liga-fish-bowl:before {
  content: "\eca1";
}
.liga-hook:before {
  content: "\eca2";
}
.liga-paw:before {
  content: "\eca3";
}
.liga-footprint:before {
  content: "\eca4";
}
.liga-speed-slow:before {
  content: "\eca5";
}
.liga-speed-medium:before {
  content: "\eca6";
}
.liga-speed-fast:before {
  content: "\eca7";
}
.liga-rocket:before {
  content: "\eca8";
}
.liga-flying-saucer:before {
  content: "\eca9";
}
.liga-flying-saucer2:before {
  content: "\ecaa";
}
.liga-hammer2:before {
  content: "\ecab";
}
.liga-balance:before {
  content: "\ecac";
}
.liga-briefcase:before {
  content: "\ecad";
}
.liga-briefcase-money:before {
  content: "\ecae";
}
.liga-briefcase-question:before {
  content: "\ecaf";
}
.liga-luggage-weight:before {
  content: "\ecb0";
}
.liga-hand-truck:before {
  content: "\ecb1";
}
.liga-construction:before {
  content: "\ecb2";
}
.liga-construction-cone:before {
  content: "\ecb3";
}
.liga-traffic-lights:before {
  content: "\ecb4";
}
.liga-siren:before {
  content: "\ecb5";
}
.liga-siren-bright:before {
  content: "\ecb6";
}
.liga-road:before {
  content: "\ecb7";
}
.liga-disc-brake:before {
  content: "\ecb8";
}
.liga-engine:before {
  content: "\ecb9";
}
.liga-oil-pressure:before {
  content: "\ecba";
}
.liga-car-battery:before {
  content: "\ecbb";
}
.liga-charging-station:before {
  content: "\ecbc";
}
.liga-gas-station:before {
  content: "\ecbd";
}
.liga-gallon:before {
  content: "\ecbe";
}
.liga-transmission:before {
  content: "\ecbf";
}
.liga-car:before {
  content: "\ecc0";
}
.liga-car-plug:before {
  content: "\ecc1";
}
.liga-car-check:before {
  content: "\ecc2";
}
.liga-car-cross:before {
  content: "\ecc3";
}
.liga-car-wash:before {
  content: "\ecc4";
}
.liga-car-lock:before {
  content: "\ecc5";
}
.liga-car-parking:before {
  content: "\ecc6";
}
.liga-bus:before {
  content: "\ecc7";
}
.liga-bus2:before {
  content: "\ecc8";
}
.liga-car2:before {
  content: "\ecc9";
}
.liga-car-lock2:before {
  content: "\ecca";
}
.liga-car-parking2:before {
  content: "\eccb";
}
.liga-police:before {
  content: "\eccc";
}
.liga-taxi:before {
  content: "\eccd";
}
.liga-jet-engine:before {
  content: "\ecce";
}
.liga-airplane:before {
  content: "\eccf";
}
.liga-airplane-cross:before {
  content: "\ecd0";
}
.liga-quadcopter:before {
  content: "\ecd1";
}
.liga-helicopter:before {
  content: "\ecd2";
}
.liga-helipad:before {
  content: "\ecd3";
}
.liga-helipad2:before {
  content: "\ecd4";
}
.liga-first-aid:before {
  content: "\ecd5";
}
.liga-ambulance:before {
  content: "\ecd6";
}
.liga-truck:before {
  content: "\ecd7";
}
.liga-truck-arrow:before {
  content: "\ecd8";
}
.liga-truck-time:before {
  content: "\ecd9";
}
.liga-truck-check:before {
  content: "\ecda";
}
.liga-truck-cross:before {
  content: "\ecdb";
}
.liga-truck-scale:before {
  content: "\ecdc";
}
.liga-train:before {
  content: "\ecdd";
}
.liga-yacht:before {
  content: "\ecde";
}
.liga-ship:before {
  content: "\ecdf";
}
.liga-anchor:before {
  content: "\ece0";
}
.liga-lighthouse:before {
  content: "\ece1";
}
.liga-boat:before {
  content: "\ece2";
}
.liga-bicycle:before {
  content: "\ece3";
}
.liga-bicycle-riding:before {
  content: "\ece4";
}
.liga-skating:before {
  content: "\ece5";
}
.liga-hiking:before {
  content: "\ece6";
}
.liga-rowing:before {
  content: "\ece7";
}
.liga-swimming:before {
  content: "\ece8";
}
.liga-swimming-warning:before {
  content: "\ece9";
}
.liga-diving:before {
  content: "\ecea";
}
.liga-diving-warning:before {
  content: "\eceb";
}
.liga-snorkeling:before {
  content: "\ecec";
}
.liga-weight-lifting:before {
  content: "\eced";
}
.liga-dumbbell:before {
  content: "\ecee";
}
.liga-baseball:before {
  content: "\ecef";
}
.liga-baseball-bat:before {
  content: "\ecf0";
}
.liga-football:before {
  content: "\ecf1";
}
.liga-hockey:before {
  content: "\ecf2";
}
.liga-golf:before {
  content: "\ecf3";
}
.liga-bowling-ball:before {
  content: "\ecf4";
}
.liga-tennis:before {
  content: "\ecf5";
}
.liga-tennis-ball:before {
  content: "\ecf6";
}
.liga-pool:before {
  content: "\ecf7";
}
.liga-ping-pong:before {
  content: "\ecf8";
}
.liga-archery:before {
  content: "\ecf9";
}
.liga-target:before {
  content: "\ecfa";
}
.liga-fencing:before {
  content: "\ecfb";
}
.liga-slingshot:before {
  content: "\ecfc";
}
.liga-soccer:before {
  content: "\ecfd";
}
.liga-basketball:before {
  content: "\ecfe";
}
.liga-whistle:before {
  content: "\ecff";
}
.liga-cube:before {
  content: "\ed00";
}
.liga-cube-front:before {
  content: "\ed01";
}
.liga-cube-side:before {
  content: "\ed02";
}
.liga-cube-top:before {
  content: "\ed03";
}
.liga-cube-bottom:before {
  content: "\ed04";
}
.liga-cube-3d:before {
  content: "\ed05";
}
.liga-puzzle:before {
  content: "\ed06";
}
.liga-puzzle-down:before {
  content: "\ed07";
}
.liga-puzzle-check:before {
  content: "\ed08";
}
.liga-puzzle-cross:before {
  content: "\ed09";
}
.liga-sunglasses:before {
  content: "\ed0a";
}
.liga-glasses:before {
  content: "\ed0b";
}
.liga-glasses2:before {
  content: "\ed0c";
}
.liga-accessibility:before {
  content: "\ed0d";
}
.liga-wheelchair:before {
  content: "\ed0e";
}
.liga-fence:before {
  content: "\ed0f";
}
.liga-bricks:before {
  content: "\ed10";
}
.liga-apps:before {
  content: "\ed11";
}
.liga-apps2:before {
  content: "\ed12";
}
.liga-apps3:before {
  content: "\ed13";
}
.liga-apps4:before {
  content: "\ed14";
}
.liga-pointer:before {
  content: "\ed15";
}
.liga-select:before {
  content: "\ed16";
}
.liga-branches2:before {
  content: "\ed17";
}
.liga-earth:before {
  content: "\ed18";
}
.liga-earth-lock:before {
  content: "\ed19";
}
.liga-network:before {
  content: "\ed1a";
}
.liga-planet:before {
  content: "\ed1b";
}
.liga-smiley:before {
  content: "\ed1c";
}
.liga-wink:before {
  content: "\ed1d";
}
.liga-smirk:before {
  content: "\ed1e";
}
.liga-smirk-wink:before {
  content: "\ed1f";
}
.liga-neutral:before {
  content: "\ed20";
}
.liga-happy:before {
  content: "\ed21";
}
.liga-grin:before {
  content: "\ed22";
}
.liga-grin-braces:before {
  content: "\ed23";
}
.liga-grin-happy:before {
  content: "\ed24";
}
.liga-poker-face:before {
  content: "\ed25";
}
.liga-pleased:before {
  content: "\ed26";
}
.liga-sad:before {
  content: "\ed27";
}
.liga-tongue:before {
  content: "\ed28";
}
.liga-tongue-wink:before {
  content: "\ed29";
}
.liga-wondering:before {
  content: "\ed2a";
}
.liga-shocked:before {
  content: "\ed2b";
}
.liga-shocked2:before {
  content: "\ed2c";
}
.liga-dork:before {
  content: "\ed2d";
}
.liga-dead:before {
  content: "\ed2e";
}
.liga-confused:before {
  content: "\ed2f";
}
.liga-baffled:before {
  content: "\ed30";
}
.liga-mad:before {
  content: "\ed31";
}
.liga-angry:before {
  content: "\ed32";
}
.liga-grin-evil:before {
  content: "\ed33";
}
.liga-evil:before {
  content: "\ed34";
}
.liga-ninja:before {
  content: "\ed35";
}
.liga-insomnia:before {
  content: "\ed36";
}
.liga-zipped:before {
  content: "\ed37";
}
.liga-skeptical:before {
  content: "\ed38";
}
.liga-sophisticated:before {
  content: "\ed39";
}
.liga-geek:before {
  content: "\ed3a";
}
.liga-cool:before {
  content: "\ed3b";
}
.liga-dreaming:before {
  content: "\ed3c";
}
.liga-scared:before {
  content: "\ed3d";
}
.liga-robot:before {
  content: "\ed3e";
}
.liga-alien:before {
  content: "\ed3f";
}
.liga-tombstone:before {
  content: "\ed40";
}
.liga-ghost:before {
  content: "\ed41";
}
.liga-ghost-neutral:before {
  content: "\ed42";
}
.liga-ghost-dead:before {
  content: "\ed43";
}
.liga-crystal-ball:before {
  content: "\ed44";
}
.liga-witch-hat:before {
  content: "\ed45";
}
.liga-halloween:before {
  content: "\ed46";
}
.liga-snowman:before {
  content: "\ed47";
}
.liga-christmas-tree:before {
  content: "\ed48";
}
.liga-easter-egg:before {
  content: "\ed49";
}
.liga-mustache:before {
  content: "\ed4a";
}
.liga-mustache-glasses:before {
  content: "\ed4b";
}
.liga-mustache-monocle:before {
  content: "\ed4c";
}
.liga-cigar:before {
  content: "\ed4d";
}
.liga-cigar-warning:before {
  content: "\ed4e";
}
.liga-cigar-no:before {
  content: "\ed4f";
}
.liga-bell:before {
  content: "\ed50";
}
.liga-bell-ringing:before {
  content: "\ed51";
}
.liga-bell2:before {
  content: "\ed52";
}
.liga-bell-plus:before {
  content: "\ed53";
}
.liga-bell-minus:before {
  content: "\ed54";
}
.liga-bell-ringing2:before {
  content: "\ed55";
}
.liga-bell-snooze:before {
  content: "\ed56";
}
.liga-bell-music:before {
  content: "\ed57";
}
.liga-bell-warning:before {
  content: "\ed58";
}
.liga-megaphone2:before {
  content: "\ed59";
}
.liga-volume-100:before {
  content: "\ed5a";
}
.liga-volume-75:before {
  content: "\ed5b";
}
.liga-volume-50:before {
  content: "\ed5c";
}
.liga-volume-25:before {
  content: "\ed5d";
}
.liga-volume-0:before {
  content: "\ed5e";
}
.liga-volume-cross:before {
  content: "\ed5f";
}
.liga-network2:before {
  content: "\ed60";
}
.liga-connection:before {
  content: "\ed61";
}
.liga-connection-warning:before {
  content: "\ed62";
}
.liga-router:before {
  content: "\ed63";
}
.liga-router-warning:before {
  content: "\ed64";
}
.liga-wifi-100:before {
  content: "\ed65";
}
.liga-wifi-lock:before {
  content: "\ed66";
}
.liga-wifi-cross:before {
  content: "\ed67";
}
.liga-wifi-75:before {
  content: "\ed68";
}
.liga-wifi-50:before {
  content: "\ed69";
}
.liga-wifi-25:before {
  content: "\ed6a";
}
.liga-wifi-100-warning:before {
  content: "\ed6b";
}
.liga-wifi-75-warning:before {
  content: "\ed6c";
}
.liga-wifi-50-warning:before {
  content: "\ed6d";
}
.liga-wifi-25-warning:before {
  content: "\ed6e";
}
.liga-stream:before {
  content: "\ed6f";
}
.liga-stream-check:before {
  content: "\ed70";
}
.liga-stream-cross:before {
  content: "\ed71";
}
.liga-stream-warning:before {
  content: "\ed72";
}
.liga-communication:before {
  content: "\ed73";
}
.liga-communication-cross:before {
  content: "\ed74";
}
.liga-hotspot:before {
  content: "\ed75";
}
.liga-hotspot2:before {
  content: "\ed76";
}
.liga-antenna:before {
  content: "\ed77";
}
.liga-satellite:before {
  content: "\ed78";
}
.liga-satellite-dish:before {
  content: "\ed79";
}
.liga-mic:before {
  content: "\ed7a";
}
.liga-mic-cross:before {
  content: "\ed7b";
}
.liga-mic-warning:before {
  content: "\ed7c";
}
.liga-mic-settings:before {
  content: "\ed7d";
}
.liga-mic-two-way:before {
  content: "\ed7e";
}
.liga-mic2:before {
  content: "\ed7f";
}
.liga-mic-cross2:before {
  content: "\ed80";
}
.liga-mic-warning2:before {
  content: "\ed81";
}
.liga-spotlights:before {
  content: "\ed82";
}
.liga-hourglass:before {
  content: "\ed83";
}
.liga-spinner:before {
  content: "\ed84";
}
.liga-spinner2:before {
  content: "\ed85";
}
.liga-spinner3:before {
  content: "\ed86";
}
.liga-spinner4:before {
  content: "\ed87";
}
.liga-undo:before {
  content: "\ed88";
}
.liga-redo:before {
  content: "\ed89";
}
.liga-leap-backward:before {
  content: "\ed8a";
}
.liga-leap-forward:before {
  content: "\ed8b";
}
.liga-cw:before {
  content: "\ed8c";
}
.liga-sync:before {
  content: "\ed8d";
}
.liga-cw-bolt:before {
  content: "\ed8e";
}
.liga-sync-warning:before {
  content: "\ed8f";
}
.liga-sync-gear:before {
  content: "\ed90";
}
.liga-sync-cross:before {
  content: "\ed91";
}
.liga-ccw:before {
  content: "\ed92";
}
.liga-cw2:before {
  content: "\ed93";
}
.liga-ccw2:before {
  content: "\ed94";
}
.liga-refund:before {
  content: "\ed95";
}
.liga-rewind-5s:before {
  content: "\ed96";
}
.liga-rewind-10s:before {
  content: "\ed97";
}
.liga-forward-10s:before {
  content: "\ed98";
}
.liga-forward-5s:before {
  content: "\ed99";
}
.liga-history:before {
  content: "\ed9a";
}
.liga-self-timer:before {
  content: "\ed9b";
}
.liga-self-timer2:before {
  content: "\ed9c";
}
.liga-watch:before {
  content: "\ed9d";
}
.liga-watch-handles:before {
  content: "\ed9e";
}
.liga-watch-health:before {
  content: "\ed9f";
}
.liga-watch-warning:before {
  content: "\eda0";
}
.liga-watch2:before {
  content: "\eda1";
}
.liga-watch-handles2:before {
  content: "\eda2";
}
.liga-clock:before {
  content: "\eda3";
}
.liga-alarm:before {
  content: "\eda4";
}
.liga-alarm2:before {
  content: "\eda5";
}
.liga-alarm-plus:before {
  content: "\eda6";
}
.liga-alarm-minus:before {
  content: "\eda7";
}
.liga-alarm-check:before {
  content: "\eda8";
}
.liga-alarm-cross:before {
  content: "\eda9";
}
.liga-stopwatch:before {
  content: "\edaa";
}
.liga-stopwatch-cross:before {
  content: "\edab";
}
.liga-stopwatch-running:before {
  content: "\edac";
}
.liga-download:before {
  content: "\edad";
}
.liga-upload2:before {
  content: "\edae";
}
.liga-import:before {
  content: "\edaf";
}
.liga-insert-down:before {
  content: "\edb0";
}
.liga-insert-left:before {
  content: "\edb1";
}
.liga-insert-right:before {
  content: "\edb2";
}
.liga-insert-up:before {
  content: "\edb3";
}
.liga-extract-down:before {
  content: "\edb4";
}
.liga-extract-left:before {
  content: "\edb5";
}
.liga-extract-right:before {
  content: "\edb6";
}
.liga-extract-up:before {
  content: "\edb7";
}
.liga-cli:before {
  content: "\edb8";
}
.liga-bug:before {
  content: "\edb9";
}
.liga-bug2:before {
  content: "\edba";
}
.liga-file-code:before {
  content: "\edbb";
}
.liga-file-compressed:before {
  content: "\edbc";
}
.liga-file-music:before {
  content: "\edbd";
}
.liga-file-video:before {
  content: "\edbe";
}
.liga-file-picture:before {
  content: "\edbf";
}
.liga-file-preview:before {
  content: "\edc0";
}
.liga-file-chart:before {
  content: "\edc1";
}
.liga-file-chart-bars:before {
  content: "\edc2";
}
.liga-file-spreadsheet:before {
  content: "\edc3";
}
.liga-file-magnifier:before {
  content: "\edc4";
}
.liga-link:before {
  content: "\edc5";
}
.liga-unlink:before {
  content: "\edc6";
}
.liga-link2:before {
  content: "\edc7";
}
.liga-unlink2:before {
  content: "\edc8";
}
.liga-link-cross:before {
  content: "\edc9";
}
.liga-thumbs-up:before {
  content: "\edca";
}
.liga-thumbs-down:before {
  content: "\edcb";
}
.liga-thumbs-up2:before {
  content: "\edcc";
}
.liga-thumbs-down2:before {
  content: "\edcd";
}
.liga-share:before {
  content: "\edce";
}
.liga-share2:before {
  content: "\edcf";
}
.liga-share3:before {
  content: "\edd0";
}
.liga-loupe:before {
  content: "\edd1";
}
.liga-loup-plus:before {
  content: "\edd2";
}
.liga-loup-minus:before {
  content: "\edd3";
}
.liga-search:before {
  content: "\edd4";
}
.liga-zoom-in:before {
  content: "\edd5";
}
.liga-zoom-out:before {
  content: "\edd6";
}
.liga-magnifier-marquee:before {
  content: "\edd7";
}
.liga-magnifier-move:before {
  content: "\edd8";
}
.liga-find-replace:before {
  content: "\edd9";
}
.liga-magnifier-next:before {
  content: "\edda";
}
.liga-magnifier-prev:before {
  content: "\eddb";
}
.liga-magnifier-check:before {
  content: "\eddc";
}
.liga-magnifier-question:before {
  content: "\eddd";
}
.liga-magnifier-user:before {
  content: "\edde";
}
.liga-magnifier-bug:before {
  content: "\eddf";
}
.liga-magnifier-dollar:before {
  content: "\ede0";
}
.liga-magnifier-euro:before {
  content: "\ede1";
}
.liga-magnifier-pound:before {
  content: "\ede2";
}
.liga-magnifier-yen:before {
  content: "\ede3";
}
.liga-magnifier-ruble:before {
  content: "\ede4";
}
.liga-menu:before {
  content: "\ede5";
}
.liga-ul:before {
  content: "\ede6";
}
.liga-ul2:before {
  content: "\ede7";
}
.liga-ol:before {
  content: "\ede8";
}
.liga-checklist2:before {
  content: "\ede9";
}
.liga-checklist-time:before {
  content: "\edea";
}
.liga-dropdown:before {
  content: "\edeb";
}
.liga-eject:before {
  content: "\edec";
}
.liga-first:before {
  content: "\eded";
}
.liga-backward:before {
  content: "\edee";
}
.liga-previous:before {
  content: "\edef";
}
.liga-previous-frame:before {
  content: "\edf0";
}
.liga-play:before {
  content: "\edf1";
}
.liga-pause:before {
  content: "\edf2";
}
.liga-next-frame:before {
  content: "\edf3";
}
.liga-stop:before {
  content: "\edf4";
}
.liga-record:before {
  content: "\edf5";
}
.liga-forward:before {
  content: "\edf6";
}
.liga-next:before {
  content: "\edf7";
}
.liga-last:before {
  content: "\edf8";
}
.liga-exclamation:before {
  content: "\edf9";
}
.liga-question:before {
  content: "\edfa";
}
.liga-check:before {
  content: "\edfb";
}
.liga-cross:before {
  content: "\edfc";
}
.liga-obelus:before {
  content: "\edfd";
}
.liga-plus:before {
  content: "\edfe";
}
.liga-minus:before {
  content: "\edff";
}
.liga-plus-minus:before {
  content: "\ee00";
}
.liga-percent:before {
  content: "\ee01";
}
.liga-code:before {
  content: "\ee02";
}
.liga-chevron-up:before {
  content: "\ee03";
}
.liga-chevron-down:before {
  content: "\ee04";
}
.liga-chevron-left:before {
  content: "\ee05";
}
.liga-chevron-right:before {
  content: "\ee06";
}
.liga-expand-vertical:before {
  content: "\ee07";
}
.liga-expand-horizontal:before {
  content: "\ee08";
}
.liga-shrink-vertical:before {
  content: "\ee09";
}
.liga-shrink-horizontal:before {
  content: "\ee0a";
}
.liga-arrow-up:before {
  content: "\ee0b";
}
.liga-arrow-down:before {
  content: "\ee0c";
}
.liga-arrow-left:before {
  content: "\ee0d";
}
.liga-arrow-right:before {
  content: "\ee0e";
}
.liga-arrow-ne:before {
  content: "\ee0f";
}
.liga-arrow-nw:before {
  content: "\ee10";
}
.liga-arrow-se:before {
  content: "\ee11";
}
.liga-arrow-sw:before {
  content: "\ee12";
}
.liga-split:before {
  content: "\ee13";
}
.liga-merge:before {
  content: "\ee14";
}
.liga-missed-outgoing2:before {
  content: "\ee15";
}
.liga-missed-incoming2:before {
  content: "\ee16";
}
.liga-enter2:before {
  content: "\ee17";
}
.liga-tab:before {
  content: "\ee18";
}
.liga-expand:before {
  content: "\ee19";
}
.liga-shrink:before {
  content: "\ee1a";
}
.liga-source:before {
  content: "\ee1b";
}
.liga-sink2:before {
  content: "\ee1c";
}
.liga-expand2:before {
  content: "\ee1d";
}
.liga-shrink2:before {
  content: "\ee1e";
}
.liga-move:before {
  content: "\ee1f";
}
.liga-swap-horizontal:before {
  content: "\ee20";
}
.liga-swap-vertical:before {
  content: "\ee21";
}
.liga-swap-calls:before {
  content: "\ee22";
}
.liga-warning:before {
  content: "\ee23";
}
.liga-stop2:before {
  content: "\ee24";
}
.liga-warning-octagon:before {
  content: "\ee25";
}
.liga-warning-circle:before {
  content: "\ee26";
}
.liga-question-circle:before {
  content: "\ee27";
}
.liga-info-circle:before {
  content: "\ee28";
}
.liga-info-octagon:before {
  content: "\ee29";
}
.liga-menu-circle:before {
  content: "\ee2a";
}
.liga-no:before {
  content: "\ee2b";
}
.liga-no-entry:before {
  content: "\ee2c";
}
.liga-tren-circle:before {
  content: "\ee2d";
}
.liga-chart-circle:before {
  content: "\ee2e";
}
.liga-side-up-circle:before {
  content: "\ee2f";
}
.liga-radio-unchecked:before {
  content: "\ee30";
}
.liga-radio-checked:before {
  content: "\ee31";
}
.liga-cross-circle:before {
  content: "\ee32";
}
.liga-check-circle:before {
  content: "\ee33";
}
.liga-download-circle:before {
  content: "\ee34";
}
.liga-upload-circle:before {
  content: "\ee35";
}
.liga-transfer-circle:before {
  content: "\ee36";
}
.liga-code-circle:before {
  content: "\ee37";
}
.liga-arrow-up-circle:before {
  content: "\ee38";
}
.liga-arrow-down-circle:before {
  content: "\ee39";
}
.liga-arrow-left-circle:before {
  content: "\ee3a";
}
.liga-arrow-right-circle:before {
  content: "\ee3b";
}
.liga-chevron-up-circle:before {
  content: "\ee3c";
}
.liga-chevron-down-circle:before {
  content: "\ee3d";
}
.liga-chevron-left-circle:before {
  content: "\ee3e";
}
.liga-chevron-right-circle:before {
  content: "\ee3f";
}
.liga-eject-circle:before {
  content: "\ee40";
}
.liga-first-circle:before {
  content: "\ee41";
}
.liga-backward-circle:before {
  content: "\ee42";
}
.liga-previous-circle:before {
  content: "\ee43";
}
.liga-previous-frame-circle:before {
  content: "\ee44";
}
.liga-play-circle:before {
  content: "\ee45";
}
.liga-pause-circle:before {
  content: "\ee46";
}
.liga-next-frame-circle:before {
  content: "\ee47";
}
.liga-stop-circle:before {
  content: "\ee48";
}
.liga-next-circle:before {
  content: "\ee49";
}
.liga-forward-circle:before {
  content: "\ee4a";
}
.liga-last-circle:before {
  content: "\ee4b";
}
.liga-equals-circle:before {
  content: "\ee4c";
}
.liga-plus-circle:before {
  content: "\ee4d";
}
.liga-minus-circle:before {
  content: "\ee4e";
}
.liga-cross-circle2:before {
  content: "\ee4f";
}
.liga-obelus-circle:before {
  content: "\ee50";
}
.liga-plus-minus-circle:before {
  content: "\ee51";
}
.liga-percent-circle:before {
  content: "\ee52";
}
.liga-infinity-circle:before {
  content: "\ee53";
}
.liga-warning-square:before {
  content: "\ee54";
}
.liga-question-square:before {
  content: "\ee55";
}
.liga-info-square:before {
  content: "\ee56";
}
.liga-menu-square:before {
  content: "\ee57";
}
.liga-exposure:before {
  content: "\ee58";
}
.liga-trend-square:before {
  content: "\ee59";
}
.liga-chart-square:before {
  content: "\ee5a";
}
.liga-side-up-square:before {
  content: "\ee5b";
}
.liga-checkbox-unchecked:before {
  content: "\ee5c";
}
.liga-cross-square:before {
  content: "\ee5d";
}
.liga-check-square:before {
  content: "\ee5e";
}
.liga-download-square:before {
  content: "\ee5f";
}
.liga-upload-square:before {
  content: "\ee60";
}
.liga-transfer-square:before {
  content: "\ee61";
}
.liga-code-square:before {
  content: "\ee62";
}
.liga-arrow-up-square:before {
  content: "\ee63";
}
.liga-arrow-down-square:before {
  content: "\ee64";
}
.liga-arrow-left-square:before {
  content: "\ee65";
}
.liga-arrow-right-square:before {
  content: "\ee66";
}
.liga-chevron-up-square:before {
  content: "\ee67";
}
.liga-chevron-down-square:before {
  content: "\ee68";
}
.liga-chevron-left-square:before {
  content: "\ee69";
}
.liga-chevron-right-square:before {
  content: "\ee6a";
}
.liga-eject-square:before {
  content: "\ee6b";
}
.liga-first-circle2:before {
  content: "\ee6c";
}
.liga-backward-square:before {
  content: "\ee6d";
}
.liga-previous-square:before {
  content: "\ee6e";
}
.liga-previous-frame-square:before {
  content: "\ee6f";
}
.liga-play-square:before {
  content: "\ee70";
}
.liga-pause-square:before {
  content: "\ee71";
}
.liga-next-frame-square:before {
  content: "\ee72";
}
.liga-stop-square:before {
  content: "\ee73";
}
.liga-next-square:before {
  content: "\ee74";
}
.liga-forward-square:before {
  content: "\ee75";
}
.liga-last-square:before {
  content: "\ee76";
}
.liga-equals-square:before {
  content: "\ee77";
}
.liga-plus-square:before {
  content: "\ee78";
}
.liga-minus-square:before {
  content: "\ee79";
}
.liga-cross-square2:before {
  content: "\ee7a";
}
.liga-obelus-square:before {
  content: "\ee7b";
}
.liga-plus-minus-square:before {
  content: "\ee7c";
}
.liga-percent-square:before {
  content: "\ee7d";
}
.liga-infinity-square:before {
  content: "\ee7e";
}
.liga-crop:before {
  content: "\ee7f";
}
.liga-crop2:before {
  content: "\ee80";
}
.liga-crop-rotate:before {
  content: "\ee81";
}
.liga-cutter:before {
  content: "\ee82";
}
.liga-shrink-square:before {
  content: "\ee83";
}
.liga-expand-square:before {
  content: "\ee84";
}
.liga-expand-square2:before {
  content: "\ee85";
}
.liga-full-screen-enter:before {
  content: "\ee86";
}
.liga-full-screen-exit:before {
  content: "\ee87";
}
.liga-full-screen-enter2:before {
  content: "\ee88";
}
.liga-full-screen-exit2:before {
  content: "\ee89";
}
.liga-focal-point:before {
  content: "\ee8a";
}
.liga-free-transform:before {
  content: "\ee8b";
}
.liga-grid:before {
  content: "\ee8c";
}
.liga-grid-cross:before {
  content: "\ee8d";
}
.liga-layers:before {
  content: "\ee8e";
}
.liga-layers-cross:before {
  content: "\ee8f";
}
.liga-fold:before {
  content: "\ee90";
}
.liga-unfold:before {
  content: "\ee91";
}
.liga-rulers:before {
  content: "\ee92";
}
.liga-ruler:before {
  content: "\ee93";
}
.liga-priority-increase:before {
  content: "\ee94";
}
.liga-priority-decrease:before {
  content: "\ee95";
}
.liga-bring-forward:before {
  content: "\ee96";
}
.liga-send-backward:before {
  content: "\ee97";
}
.liga-move-up:before {
  content: "\ee98";
}
.liga-move-down:before {
  content: "\ee99";
}
.liga-group2:before {
  content: "\ee9a";
}
.liga-group3:before {
  content: "\ee9b";
}
.liga-resize:before {
  content: "\ee9c";
}
.liga-scale-down:before {
  content: "\ee9d";
}
.liga-scale-up:before {
  content: "\ee9e";
}
.liga-squash:before {
  content: "\ee9f";
}
.liga-funnel:before {
  content: "\eea0";
}
.liga-flip-vertical:before {
  content: "\eea1";
}
.liga-flip-horizontal:before {
  content: "\eea2";
}
.liga-flip-vertical2:before {
  content: "\eea3";
}
.liga-flip-horizontal2:before {
  content: "\eea4";
}
.liga-round-corner:before {
  content: "\eea5";
}
.liga-angle:before {
  content: "\eea6";
}
.liga-angle2:before {
  content: "\eea7";
}
.liga-combine:before {
  content: "\eea8";
}
.liga-subtract:before {
  content: "\eea9";
}
.liga-intersect:before {
  content: "\eeaa";
}
.liga-exclude:before {
  content: "\eeab";
}
.liga-align-center-vertical:before {
  content: "\eeac";
}
.liga-align-left:before {
  content: "\eead";
}
.liga-align-right:before {
  content: "\eeae";
}
.liga-align-bottom:before {
  content: "\eeaf";
}
.liga-align-center-horizontal:before {
  content: "\eeb0";
}
.liga-align-top:before {
  content: "\eeb1";
}
.liga-canvas:before {
  content: "\eeb2";
}
.liga-canvas-fit:before {
  content: "\eeb3";
}
.liga-canvas-bottom:before {
  content: "\eeb4";
}
.liga-canvas-center:before {
  content: "\eeb5";
}
.liga-canvas-left:before {
  content: "\eeb6";
}
.liga-canvas-right:before {
  content: "\eeb7";
}
.liga-canvas-top:before {
  content: "\eeb8";
}
.liga-move-bottom-up:before {
  content: "\eeb9";
}
.liga-move-top-down:before {
  content: "\eeba";
}
.liga-move-left-right:before {
  content: "\eebb";
}
.liga-move-right-left:before {
  content: "\eebc";
}
.liga-move-bottom-down:before {
  content: "\eebd";
}
.liga-move-top-up:before {
  content: "\eebe";
}
.liga-move-left-left:before {
  content: "\eebf";
}
.liga-move-right-right:before {
  content: "\eec0";
}
.liga-ligature:before {
  content: "\eec1";
}
.liga-format-shapes:before {
  content: "\eec2";
}
.liga-text-color:before {
  content: "\eec3";
}
.liga-format-clear:before {
  content: "\eec4";
}
.liga-text-size:before {
  content: "\eec5";
}
.liga-text-size2:before {
  content: "\eec6";
}
.liga-text-size-increase:before {
  content: "\eec7";
}
.liga-text-size-decrease:before {
  content: "\eec8";
}
.liga-bold:before {
  content: "\eec9";
}
.liga-italic:before {
  content: "\eeca";
}
.liga-underline:before {
  content: "\eecb";
}
.liga-strikethrough:before {
  content: "\eecc";
}
.liga-text-down:before {
  content: "\eecd";
}
.liga-subscript:before {
  content: "\eece";
}
.liga-superscript:before {
  content: "\eecf";
}
.liga-highlight:before {
  content: "\eed0";
}
.liga-text-align-left:before {
  content: "\eed1";
}
.liga-text-align-center:before {
  content: "\eed2";
}
.liga-text-align-right:before {
  content: "\eed3";
}
.liga-text-align-justify:before {
  content: "\eed4";
}
.liga-line-spacing:before {
  content: "\eed5";
}
.liga-indent-decrease:before {
  content: "\eed6";
}
.liga-indent-increase:before {
  content: "\eed7";
}
.liga-text-around:before {
  content: "\eed8";
}
.liga-pilcrow:before {
  content: "\eed9";
}
.liga-direction-ltr:before {
  content: "\eeda";
}
.liga-direction-rtl:before {
  content: "\eedb";
}
.liga-page-break:before {
  content: "\eedc";
}
.liga-sort-alpha-asc:before {
  content: "\eedd";
}
.liga-sort-alpha-desc:before {
  content: "\eede";
}
.liga-sort-numeric-asc:before {
  content: "\eedf";
}
.liga-sort-numeric-desc:before {
  content: "\eee0";
}
.liga-sort-time-asc:before {
  content: "\eee1";
}
.liga-sort-time-desc:before {
  content: "\eee2";
}
.liga-sort-size-asc:before {
  content: "\eee3";
}
.liga-sort-size-desc:before {
  content: "\eee4";
}
.liga-sigma:before {
  content: "\eee5";
}
.liga-omega:before {
  content: "\eee6";
}
.liga-sine:before {
  content: "\eee7";
}
.liga-cosine:before {
  content: "\eee8";
}
.liga-border-color:before {
  content: "\eee9";
}
.liga-handwriting:before {
  content: "\eeea";
}
.liga-fingerprint:before {
  content: "\eeeb";
}
.liga-palm:before {
  content: "\eeec";
}
.liga-hand-grip:before {
  content: "\eeed";
}
.liga-pointer-up:before {
  content: "\eeee";
}
.liga-pointer-down:before {
  content: "\eeef";
}
.liga-pointer-left:before {
  content: "\eef0";
}
.liga-pointer-right:before {
  content: "\eef1";
}
.liga-tap-all:before {
  content: "\eef2";
}
.liga-tap:before {
  content: "\eef3";
}
.liga-tap-two:before {
  content: "\eef4";
}
.liga-gesture-zoom:before {
  content: "\eef5";
}
.liga-gesture-pinch:before {
  content: "\eef6";
}
.liga-swipe-horizontal:before {
  content: "\eef7";
}
.liga-swipe-left:before {
  content: "\eef8";
}
.liga-swipe-right:before {
  content: "\eef9";
}
.liga-swipe-vertical:before {
  content: "\eefa";
}
.liga-swipe-up:before {
  content: "\eefb";
}
.liga-swipe-down:before {
  content: "\eefc";
}
.liga-scroll-horizontal:before {
  content: "\eefd";
}
.liga-scroll-left:before {
  content: "\eefe";
}
.liga-scroll-right:before {
  content: "\eeff";
}
.liga-scroll-vertical:before {
  content: "\ef00";
}
.liga-scroll-up:before {
  content: "\ef01";
}
.liga-scroll-down:before {
  content: "\ef02";
}
.liga-reminder:before {
  content: "\ef03";
}
.liga-horns:before {
  content: "\ef04";
}
.liga-ellipsis:before {
  content: "\ef05";
}
.liga-menu2:before {
  content: "\ef06";
}
.liga-uni21:before {
  content: "\21";
}
.liga-uni22:before {
  content: "\22";
}
.liga-uni23:before {
  content: "\23";
}
.liga-uni24:before {
  content: "\24";
}
.liga-uni25:before {
  content: "\25";
}
.liga-uni26:before {
  content: "\26";
}
.liga-uni27:before {
  content: "\27";
}
.liga-uni28:before {
  content: "\28";
}
.liga-uni29:before {
  content: "\29";
}
.liga-uni2a:before {
  content: "\2a";
}
.liga-uni2b:before {
  content: "\2b";
}
.liga-uni2c:before {
  content: "\2c";
}
.liga-uni2d:before {
  content: "\2d";
}
.liga-uni2e:before {
  content: "\2e";
}
.liga-uni2f:before {
  content: "\2f";
}
.liga-uni30:before {
  content: "\30";
}
.liga-uni31:before {
  content: "\31";
}
.liga-uni32:before {
  content: "\32";
}
.liga-uni33:before {
  content: "\33";
}
.liga-uni34:before {
  content: "\34";
}
.liga-uni35:before {
  content: "\35";
}
.liga-uni36:before {
  content: "\36";
}
.liga-uni37:before {
  content: "\37";
}
.liga-uni38:before {
  content: "\38";
}
.liga-uni39:before {
  content: "\39";
}
.liga-uni3a:before {
  content: "\3a";
}
.liga-uni3b:before {
  content: "\3b";
}
.liga-uni3c:before {
  content: "\3c";
}
.liga-uni3d:before {
  content: "\3d";
}
.liga-uni3e:before {
  content: "\3e";
}
.liga-uni3f:before {
  content: "\3f";
}
.liga-uni40:before {
  content: "\40";
}
.liga-uni41:before {
  content: "\41";
}
.liga-uni42:before {
  content: "\42";
}
.liga-uni43:before {
  content: "\43";
}
.liga-uni44:before {
  content: "\44";
}
.liga-uni45:before {
  content: "\45";
}
.liga-uni46:before {
  content: "\46";
}
.liga-uni47:before {
  content: "\47";
}
.liga-uni48:before {
  content: "\48";
}
.liga-uni49:before {
  content: "\49";
}
.liga-uni4a:before {
  content: "\4a";
}
.liga-uni4b:before {
  content: "\4b";
}
.liga-uni4c:before {
  content: "\4c";
}
.liga-uni4d:before {
  content: "\4d";
}
.liga-uni4e:before {
  content: "\4e";
}
.liga-uni4f:before {
  content: "\4f";
}
.liga-uni50:before {
  content: "\50";
}
.liga-uni51:before {
  content: "\51";
}
.liga-uni52:before {
  content: "\52";
}
.liga-uni53:before {
  content: "\53";
}
.liga-uni54:before {
  content: "\54";
}
.liga-uni55:before {
  content: "\55";
}
.liga-uni56:before {
  content: "\56";
}
.liga-uni57:before {
  content: "\57";
}
.liga-uni58:before {
  content: "\58";
}
.liga-uni59:before {
  content: "\59";
}
.liga-uni5a:before {
  content: "\5a";
}
.liga-uni5b:before {
  content: "\5b";
}
.liga-uni5c:before {
  content: "\5c";
}
.liga-uni5d:before {
  content: "\5d";
}
.liga-uni5e:before {
  content: "\5e";
}
.liga-uni5f:before {
  content: "\5f";
}
.liga-uni60:before {
  content: "\60";
}
.liga-uni61:before {
  content: "\61";
}
.liga-uni62:before {
  content: "\62";
}
.liga-uni63:before {
  content: "\63";
}
.liga-uni64:before {
  content: "\64";
}
.liga-uni65:before {
  content: "\65";
}
.liga-uni66:before {
  content: "\66";
}
.liga-uni67:before {
  content: "\67";
}
.liga-uni68:before {
  content: "\68";
}
.liga-uni69:before {
  content: "\69";
}
.liga-uni6a:before {
  content: "\6a";
}
.liga-uni6b:before {
  content: "\6b";
}
.liga-uni6c:before {
  content: "\6c";
}
.liga-uni6d:before {
  content: "\6d";
}
.liga-uni6e:before {
  content: "\6e";
}
.liga-uni6f:before {
  content: "\6f";
}
.liga-uni70:before {
  content: "\70";
}
.liga-uni71:before {
  content: "\71";
}
.liga-uni72:before {
  content: "\72";
}
.liga-uni73:before {
  content: "\73";
}
.liga-uni74:before {
  content: "\74";
}
.liga-uni75:before {
  content: "\75";
}
.liga-uni76:before {
  content: "\76";
}
.liga-uni77:before {
  content: "\77";
}
.liga-uni78:before {
  content: "\78";
}
.liga-uni79:before {
  content: "\79";
}
.liga-uni7a:before {
  content: "\7a";
}
.liga-uni7b:before {
  content: "\7b";
}
.liga-uni7c:before {
  content: "\7c";
}
.liga-uni7d:before {
  content: "\7d";
}
.liga-unia9:before {
  content: "\a9";
}
.liga-unid7:before {
  content: "\d7";
}
.liga-uni2018:before {
  content: "\2018";
}
.liga-uni2019:before {
  content: "\2019";
}
.liga-uni201c:before {
  content: "\201c";
}
.liga-uni201d:before {
  content: "\201d";
}
.liga-uni2026:before {
  content: "\2026";
}
