:root {
  --primaryBg: 255, 255, 255;
  --secondaryBg: 128, 128, 128;
  --tertiaryBg: 30, 30, 30;
  --quinaryBg: 248, 246, 247;
  --quaternaryBg: 212, 212, 212;
  --senaryBg: 204, 204, 204;
  --headerBg: 0, 0, 0;
  --headerSubBg: 255, 255, 255;
  --headerFg: 255, 255, 255;
  --headerSubFg: 0, 0, 0;
  --headerSubSecondaryFg: 115, 115, 115;
  --footerBg: 45, 45, 45;
  --overlayBg: 0, 0, 0;
  --inputBg: 255, 255, 255;
  --sliderIndicatorBg: 161, 161, 170;
  --sliderActiveIndicatorBg: 255, 255, 255;

  --inputFg: 0, 0, 0;
  --primaryFg: 0, 0, 0;
  --secondaryFg: 82, 82, 82;
  --tertiaryFg: 107, 114, 128;
  --quinaryFg: 153, 153, 153;
  --quaternaryFg: 107, 33, 168;
  --senaryFg: 75, 75, 75;
  --septenaryFg: 115, 115, 115;
  --octonaryFg: 17, 24, 39;
  --nonaryFg: 255, 255, 255;
  --successFg: 14, 131, 69;

  --searchBarBorder: 82, 82, 82;
  --searchItemBorder: 244, 244, 245;
  --searchBarFg: 82, 82, 82;
  --searchBarBg: 255, 255, 255;

  --primaryFavBg: 0, 0, 0;
  --secondaryFavBg: 255, 255, 255;
  --primaryFavFg: 255, 255, 255;
  --secondaryFavFg: 0, 0, 0;

  --favBtnBg: 255, 255, 255;
  --favBtnFg: 0, 0, 0;
  --favBtnBorder: 0, 0, 0;

  --primaryBtnBg: 126, 34, 206;
  --primaryBtnFg: 255, 255, 255;

  --secondaryBtnBg: 0, 0, 0;
  --secondaryBtnFg: 255, 255, 255;

  --tertiaryBtnBg: 255, 255, 255;
  --tertiaryBtnFg: 0, 0, 0;

  --disableBtnBg: 212, 212, 212;

  --skeletonFg: 229, 231, 235;
  --skeletonBg: 209, 213, 219;

  --inputBorder: 0, 0, 0;
  --primaryBorder: 209, 213, 219;
  --secondaryBorder: 232, 232, 232;
  --tertiaryBorder: 204, 204, 204;
  --quinaryBorder: 107, 114, 128;
  --quaternaryBorder: 192, 132, 252;
  --senaryBorder: 187, 187, 187;

  --ticketSelected: 253, 193, 8;
  --ticketReserved: 249, 43, 181;
  --ticketUnavailable: 209, 213, 219;
}
