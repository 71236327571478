/* You can add global styles to this file, and also import other style files */
@import "./assets/fonts/lindua-icons.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "./assets/styles/ng-select.scss";
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
// regular style toast
@import "ngx-toastr/toastr";

@import "./assets/styles/theme";

@tailwind base;
@tailwind components;
@tailwind utilities;

.cdk-overlay-container {
  z-index: 10000;
}
.owl-dt-container-info.owl-dt-container-row {
  display: none;
}

@layer base {
  @font-face {
    font-family: "iconbz";
    src: url("/assets/fonts/icomoon.eot?dm1hgm");
    src: url("/assets/fonts/icomoon.eot?dm1hgm#iefix")
        format("embedded-opentype"),
      url("/assets/fonts/icomoon.ttf?dm1hgm") format("truetype"),
      url("/assets/fonts/icomoon.woff?dm1hgm") format("woff"),
      url("/assets/fonts/icomoon.svg?dm1hgm#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
}

.icon-o {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 40;
}

body {
  min-height: 100svh;
  touch-action: manipulation;
  @apply font-dmSans text-base bg-primaryBg text-primaryFg #{!important};
}

.input-wrapper,
input {
  @apply bg-inputBg text-inputFg;
}

body.dark {
  @apply text-white;
}

[class^="iconbz-"],
[class*=" iconbz-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "iconbz" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
  content: "\e90b";
  color: #4b4b4b;
}

.iconbz-bookmark:before {
  content: "\e900";
}
.iconbz-filter:before {
  content: "\e901";
}
.iconbz-search:before {
  content: "\e902";
}
.iconbz-calendar:before {
  content: "\e903";
}
.iconbz-share:before {
  content: "\e904";
}
.iconbz-lock:before {
  content: "\e905";
}
.iconbz-chevron-left:before {
  content: "\e906";
}
.iconbz-home:before {
  content: "\e907";
}
.iconbz-user:before {
  content: "\e908";
}
.iconbz-map-pin:before {
  content: "\e909";
}
.iconbz-exhibition:before {
  content: "\e90a";
}
.iconbz-arrow-right:before {
  content: "\e90b";
}
.iconbz-cinema:before {
  content: "\e90c";
}
.iconbz-mic:before {
  content: "\e90d";
}
.iconbz-arrow-up-right:before {
  content: "\e90e";
}
.iconbz-calendar1:before {
  content: "\e910";
}
.iconbz-search1:before {
  content: "\e911";
}
.iconbz-facebook:before {
  content: "\e912";
}
.iconbz-Facebook:before {
  content: "\e912";
}
.iconbz-instagram:before {
  content: "\e913";
}
.iconbz-İnstagram:before {
  content: "\e913";
}
.iconbz-twitter:before {
  content: "\e914";
}
.iconbz-Twitter:before {
  content: "\e914";
}
.iconbz-x:before {
  content: "\e914";
}
.iconbz-X:before {
  content: "\e914";
}
.iconbz-theater:before {
  content: "\e915";
}
.iconbz-stand-up:before {
  content: "\e91d";
}
.iconbz-festival:before {
  content: "\e92d";
}
.iconbz-mic1:before {
  content: "\e92f";
}
.iconbz-envelop:before {
  content: "\e945";
}
.iconbz-whatsapp:before {
  content: "\ea93";
}
.iconbz-spotify:before {
  content: "\ea94";
}
.iconbz-Spotify:before {
  content: "\ea94";
}
.iconbz-telegram:before {
  content: "\ea95";
}
.iconbz-Telegram:before {
  content: "\ea95";
}
.iconbz-youtube:before {
  content: "\ea9d";
}
.iconbz-Youtube:before {
  content: "\ea9d";
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

ng-dropdown-panel.ng-dropdown-panel {
  z-index: 9999;
}

.toast-container.toast-bottom-center {
  @apply bottom-16 md:right-6 md:w-auto md:left-auto;
  .ngx-toastr {
    @apply w-[calc(100%-32px)] md:w-80;
  }
}

.ng-placeholder {
  @apply absolute top-1/2 -translate-y-1/2 p-0 #{!important};
}
