.ng-select .ng-select-container {
  padding: 0.4375rem 0.3125rem;
  border-radius: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #6c22a610;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border: none;
  font-family: "Lindua" !important;
  width: auto !important;
  height: auto !important;
  &::before {
    content: "\ee04";
  }
}
.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  position: static;
  &::before {
    content: "\ee03";
  }
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding: 0 0 0.3125rem 0.1875rem;
  line-height: 30px;
}

.ng-select.ng-select-single .ng-select-container {
  height: 3rem;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 11px;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value,
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon {
  background-color: #6c22a6;
  color: white;
  border-color: #6c22a6 !important;
  &:hover {
    background-color: #6c22a680;
    color: white;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  &:hover {
    background-color: #6c22a680;
    color: white;
  }
  &.ng-option-selected {
    background-color: #6c22a6;
    color: white;
    &:hover {
      background-color: #6c22a680;
      color: white;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  background-color: #6c22a680;
  color: white;
}
